<template>
    <div id="auth">
        <login-header />
        <div id="auth__body">
            <router-view class="base-main"></router-view>
        </div>
    </div>
</template>

<script>
import LoginHeader from '@/components/organisms/headers/LoginHeader'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'

export default {
    name: 'LayoutAuth',

    components: {
        LoginHeader
    },

    created() {
        // 認証済みの場合はトップページへ遷移する
        if (AuthRepository.isLoggedIn()) {
            this.$router.push({ name: 'portalIndex' })
        }
    }
}
</script>

<style lang="scss" scoped>
#auth {
    margin: 0 auto;

    &__body {
        // width: 986px;
        height: 400px;
        margin: 0 auto;
    }
}
</style>