export const UnitPriceList = [
    {
        name: '10万円',
        value: 100000
    },
    {
        name: '20万円',
        value: 200000
    },
    {
        name: '30万円',
        value: 300000
    },
    {
        name: '40万円',
        value: 400000
    },
    {
        name: '50万円',
        value: 500000
    },
    {
        name: '60万円',
        value: 600000
    },
    {
        name: '70万円',
        value: 700000
    },
    {
        name: '80万円',
        value: 800000
    },
    {
        name: '90万円',
        value: 900000
    },
    {
        name: '100万円',
        value: 1000000
    },
    {
        name: '150万円',
        value: 1500000
    },
    {
        name: '200万円',
        value: 2000000
    },
    {
        name: '250万円',
        value: 2500000
    },
    {
        name: '300万円',
        value: 3000000
    },
    {
        name: '350万円',
        value: 3500000
    },
    {
        name: '400万円',
        value: 4000000
    },
    {
        name: '450万円',
        value: 4500000
    },
    {
        name: '500万円',
        value: 5000000
    },
    {
        name: '550万円',
        value: 5500000
    },
    {
        name: '600万円',
        value: 6000000
    },
    {
        name: '650万円',
        value: 6500000
    },
    {
        name: '700万円',
        value: 7000000
    },
    {
        name: '750万円',
        value: 7500000
    },
    {
        name: '800万円',
        value: 8000000
    },
    {
        name: '850万円',
        value: 8500000
    },
    {
        name: '900万円',
        value: 9000000
    },
    {
        name: '950万円',
        value: 9500000
    },
    {
        name: '1000万円',
        value: 10000000
    },
]

export const UnitPriceList2 = [
    {
        name: '50万円',
        value: 500000
    },
    {
        name: '100万円',
        value: 1000000
    },
    {
        name: '150万円',
        value: 1500000
    },
    {
        name: '200万円',
        value: 2000000
    },
    {
        name: '250万円',
        value: 2500000
    },
    {
        name: '300万円',
        value: 3000000
    },
    {
        name: '350万円',
        value: 3500000
    },
    {
        name: '400万円',
        value: 4000000
    },
    {
        name: '450万円',
        value: 4500000
    },
    {
        name: '500万円',
        value: 5000000
    },
    {
        name: '550万円',
        value: 5500000
    },
    {
        name: '600万円',
        value: 6000000
    },
    {
        name: '650万円',
        value: 6500000
    },
    {
        name: '700万円',
        value: 7000000
    },
    {
        name: '750万円',
        value: 7500000
    },
    {
        name: '800万円',
        value: 8000000
    },
    {
        name: '850万円',
        value: 8500000
    },
    {
        name: '900万円',
        value: 9000000
    },
    {
        name: '950万円',
        value: 9500000
    },
    {
        name: '1000万円',
        value: 10000000
    },
]
