<template>
    <div>
        <div class="login">

            <div class="signin signin--forget">
                <validation-observer tag='div' ref="login" v-slot="{ invalid }">
                    <h4 class="signin__title">パスワード再設定</h4>

                    <p v-if="errors.message" class="errors">{{ errors.message }}</p>

                    <div class="signin__messageBlock">
                        <p>パスワードリセット用のURLを送信します。<br>登録済のメールアドレスを入力してください。</p>
                    </div>

                    <base-input-field name="email" label="メールアドレス" rules="required|email|max:254" :apiErrors="errors" @update:field="profile.email = $event" required />
                    <div>
                        <base-button @click="submit" class="signin__login" :disabled="invalid">送信する</base-button>
                    </div>

                    <div class="login-menu">
                        <div class="login-menu--register">
                            <router-link :to="{ name: 'login' }" class="login-menu__link">ログイン</router-link> <span>へ戻る</span>
                        </div>
                    </div>
                </validation-observer>

            </div>
        </div>
    </div>
</template>

<script>
import BaseInputField from '@/components/atoms/inputs/BaseInputField'
import BaseButton from '@/components/atoms/buttons/BaseButton'
import { ValidationObserver } from "vee-validate";
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
    name: 'ForgetPassword',

    components: {
        BaseInputField,
        BaseButton,
        ValidationObserver
    },

    data() {
        return {
            profile: {
                email: ''
            }
        }
    },

    computed: {
        ...mapState({
            errors: state => state.auth.errors,
            apiStatus: state => state.auth.apiStatus
        })
    },

    methods: {
        // メール送信
        async submit() {
            // パスワード再設定リクエスト
            await this.$store.dispatch('auth/requestResetPassword', this.profile)

            if (this.apiStatus) {
                Message({
                    message: 'ご登録のアドレスに送られたメールから新しいパスワードを設定してください',
                    type: 'success',
                    duration: 0,
                    showClose: true
                });

                setTimeout(() => {
                    this.$router.push({ name: 'portalIndex' })
                }, 3000);
            }
        }
    }
}
</script>