<template>
    <div
        @click="handleClick"
        class="portalBtn"
        :class="[
            type ? 'portalBtn--' + type : ''
        ]"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PortalBaseButton',

    props: {
        type: {
            type: String,
            default: 'default'
        }
    },

    methods: {
        handleClick() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.portalBtn {
    width: 168px;
    height: 38px;
    padding: 3px 10px;
    line-height: 30px;
    text-align: center;
    border-radius: 1px;
    cursor: pointer;

    font-size: 12px;
    font-weight: bold;
    color: #fff;

    &--favorite {
        border: 1px solid #F77C00;
        color: #0c0600;
        transition: all .2s;
        font-weight: 500;

        &:hover &--active {
            font-weight: 800;
            background-color: #F77C00;
            color: #fff;
        }
    }

    &--share {
        background-color: #909399;
    }

    &--default {
         background-color: #F77C00;
    }

    &--public {
        background-color: #007bf7;
    }


    &:hover {
        opacity: 0.8;
    }
}
</style>