<template>
    <div>
        <div class="p-sidebar">
            <div class="p-sidebar__header" @click="handle">
                <span>検索条件変更</span>
                <div class="p-sidebar__handle">
                    <span class="p-sidebar__close" id="sidebar-close">
                        <img src="../../../assets/images/common/arrow.png" style="width: 12px;">
                    </span>
                    <span class="p-sidebar__open" id="sidebar-open">
                        <img src="../../../assets/images/common/arrow.png" style="width: 12px; ">
                    </span>
                </div>
            </div>
            <div class="p-sidebar__body" id="sidebar-body">

                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle" data-toggle="collapse" href="#collapse5" role="button" aria-expanded="true" aria-controls="collapse5">新着物件</div>
                    <div class="p-sidebar__itemSelectLayout collapse show" id="collapse5">
                        <div>
                            <input class="form-control" type="date" v-model="params.datePre">
                            <span>〜</span>
                            <input class="form-control" type="date" v-model="params.datePost">
                        </div>
                    </div>
                </div>

                <!-- 建物タイプ -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">建物タイプ</div>
                    <div class="p-sidebar__itemOption">
                        <ul class="p-sidebar__layout--list">
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.usage" type="checkbox" id="structure1" value="1" class="search-checkbox">
                                <label for="structure1">一棟</label>
                            </li>
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.usage" type="checkbox" id="structure2" value="2" class="search-checkbox">
                                <label for="structure2">区分</label>
                            </li>
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.usage" type="checkbox" id="structure3" value="3" class="search-checkbox">
                                <label for="structure3">戸建</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- エリア -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">エリア</div>

                    <div class="check-all">
                        <input @change="checkAllArea" type="checkbox" id="area-all" class="search-checkbox">
                        <label for="area-all">23区全て</label>
                    </div>

                    <div class="p-sidebar__itemOption">
                        <ul class="p-sidebar__layout--list">
                            <li v-for="(area, index) in areaList" :key="index" class="p-sidebar__layout--item">
                                <input type="checkbox" v-model="params.city" :id="`area${index}`" :value="area.id" class="search-checkbox">
                                <label :for="`area${index}`">{{ area.value }}</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 価格 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">価格</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minPrice" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(price, index) in priceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxPrice" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(price, index) in priceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- 延床坪単価 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">延床坪単価</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minTotalFloorUnitPrice" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(price, index) in unitPriceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxTotalFloorUnitPrice" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(price, index) in unitPriceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- 専有坪単価 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">専有坪単価</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minOccupiedUnitPrice" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(price, index) in unitPriceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxOccupiedUnitPrice" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(price, index) in unitPriceList" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- 土地坪単価 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">土地坪単価</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minLandUnitPrice" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(price, index) in unitPriceList2" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxLandUnitPrice" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(price, index) in unitPriceList2" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- 土地坪単価 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">延床面積</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minTotalFloorArea" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(item, index) in land" :key="index" :value="item.id">{{ item.value }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxTotalFloorArea" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(item, index) in land" :key="index" :value="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>

                <!-- 土地坪単価 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">専有面積</div>
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minOccupiedArea" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(item, index) in land" :key="index" :value="item.id">{{ item.value }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxOccupiedArea" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(item, index) in land" :key="index" :value="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>

                <!-- 残存耐用年数  -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle" data-toggle="collapse" href="#collapse5" role="button" aria-expanded="true" aria-controls="collapse5">残存耐用年数</div>
                    <div class="p-sidebar__itemSelectLayout collapse show" id="collapse5">
                        <select v-model="params.minLife" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(price, index) in remainUsefulLife" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxLife" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(price, index) in remainUsefulLife" :key="index" :value="price.value">{{ price.name }}</option>
                        </select>
                    </div>
                </div>

                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">権利形態</div>
                    <div class="p-sidebar__itemOption">
                        <ul class="p-sidebar__layout--list">
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.right" type="checkbox" id="right1" value="1" class="search-checkbox">
                                <label for="right1">所有権</label>
                            </li>
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.right" type="checkbox" id="right2" value="2" class="search-checkbox">
                                <label for="right2">借地権</label>
                            </li>
                            <li class="p-sidebar__layout--item">
                                <input v-model="params.right" type="checkbox" id="right3" value="3" class="search-checkbox">
                                <label for="right3">その他</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="p-sidebar__searchBtn">
                    <base-button class="searchBtn" @click="actionSearch">この条件で検索する</base-button>
                </div>

            </div>
        </div>

        <div class="p-sidebar" style="margin-top: 50px;">
            <div class="p-sidebar__header" @click="handleSecond">
                <span>2次フィルター</span>
                <div class="p-sidebar__handle">
                    <span @click="handleCloseSecond" class="p-sidebar__close" id="sidebar-close--second">
                        <img src="../../../assets/images/common/arrow.png" style="width: 12px;">
                    </span>
                    <span @click="handleOpenSecond" class="p-sidebar__open" id="sidebar-open--second">
                        <img src="../../../assets/images/common/arrow.png" style="width: 12px; ">
                    </span>
                </div>
            </div>
            <div class="p-sidebar__body" id="sidebar-body--second">

                <!-- 住宅ローン月額支払 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle" href="#collapse4" role="button">住宅ローン月額支払（35年）</div>
                    <div class="p-sidebar__itemSelectLayout collapse show">
                        <select v-model="params.minLoan" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(loan, index) in loanList" :key="index" :value="loan.value">{{ loan.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxLoan" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(loan, index) in loanList" :key="index" :value="loan.value">{{ loan.name }}</option>
                        </select>
                    </div>

                    <div style="margin-top: 20px;">
                        <div class="p-sidebar__itemOption">
                            <ul class="p-sidebar__layout--list">
                                <li class="p-sidebar__layout--item">
                                    <el-radio v-model="params.interest" label="1">金利0.75%</el-radio>
                                </li>
                                <li class="p-sidebar__layout--item">
                                    <el-radio v-model="params.interest" label="2">金利1.00%</el-radio>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <!-- 相続対策  -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle">相続税対策（圧縮率）</div>
                    <!-- <input v-model="params.inheritance" type="checkbox" id="inheritance" true-value="on" false-value="" class="search-checkbox"> -->
                    <div class="p-sidebar__itemSelectLayout">
                        <select v-model="params.minCompressibility" name="" id="" class="search-select">
                            <option value="">下限なし</option>
                            <option v-for="(compressibility, index) in compressibilityList" :key="index" :value="compressibility.value">{{ compressibility.name }}</option>
                        </select>
                        <span>〜</span>
                        <select v-model="params.maxCompressibility" name="" id="" class="search-select">
                            <option value="">上限なし</option>
                            <option v-for="(compressibility, index) in compressibilityList" :key="index" :value="compressibility.value">{{ compressibility.name }}</option>
                        </select>
                    </div>
                </div>

                <!-- 積算オーバー -->
                <div class="p-sidebar__item p-sidebar__item--flex">
                    <div class="p-sidebar__itemTitle p-sidebar__itemTitle--flex">
                        <span>積算オーバー</span>
                        <input v-model="params.integration_over" type="checkbox" id="integration_over" true-value="on" false-value="" class="search-checkbox">
                    </div>
                </div>

                <!-- 相場以下物件 -->
                <div class="p-sidebar__item">
                    <div class="p-sidebar__itemTitle p-sidebar__itemTitle--flex">
                        <span>相場以下物件</span>
                        <input v-model="params.market_under" type="checkbox" id="market_under" true-value="on" false-value="" class="search-checkbox">
                    </div>
                </div>

                <div class="p-sidebar__searchBtn">
                    <base-button class="searchBtn" @click="actionSearch">この条件で検索する</base-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/atoms/buttons/BaseButton'
import { AreaList } from '@/definitions/portal/area_list'
import { PriceList } from '@/definitions/portal/price_list'
import { UnitPriceList, UnitPriceList2 } from '@/definitions/portal/unit_price_list'
import { GrossList } from '@/definitions/portal/gross_list'
import { RemainUsefulLife } from '@/definitions/portal/remaining_useful_life'
import { LoanList1 } from '@/definitions/portal/loan/loan_list_1'
import { LoanList2 } from '@/definitions/portal/loan/loan_list_2'
import { CompressibilityList } from '@/definitions/portal/compressibility_list'
import { Land } from '@/definitions/portal/land'

export default {
    name: "PortalSidebar",

    components: {
        BaseButton
    },

    props: {
        searchCriteria: {
            type: Object
        }
    },

    data() {
        return {
            params: {
                city: [],
                datePre: '',
                datePost: '',
                minPrice: '',
                maxPrice: '',
                minTotalFloorUnitPrice: '',
                maxTotalFloorUnitPrice: '',
                minOccupiedUnitPrice: '',
                maxOccupiedUnitPrice: '',
                minLandUnitPrice: '',
                maxLandUnitPrice: '',
                usage: [],
                right: [],
                detailed: [],
                maxGross: '',
                minGross: '',
                market_under: '',
                inheritance: '',
                integration_over: '',
                minTotalFloorArea: '',
                maxTotalFloorArea: '',
                minOccupiedArea: '',
                maxOccupiedArea: '',
                minLife: '',
                maxLife: '',
                minLoan: '',
                maxLoan: '',
                minCompressibility: '',
                maxCompressibility: '',
                interest: '1',
                page: 1,
                sortValue: 'acquisition_date',
                sort: 'desc',
            },
            isCheckAllArea: false,
            isOpenSideHandle: true,
            isOpenSecondSideHandle: true,
        }
    },

    watch: {
        searchCriteria: {
            handler(val) {
                this.params = val
            },
            deep: true
        },
    },

    computed: {
        areaList() {
            return AreaList
        },
        priceList() {
            return PriceList
        },
        unitPriceList() {
            return UnitPriceList
        },
        unitPriceList2() {
            return UnitPriceList2
        },
        grossList() {
            return GrossList
        },
        remainUsefulLife() {
            return RemainUsefulLife
        },
        loanList() {
            return this.params.interest == 1 ? LoanList1 : LoanList2
        },
        compressibilityList() {
            return CompressibilityList
        },
        land() {
            return Land
        }
    },

    methods: {
        actionSearch(page = null) {
            this.params.page = page

            this.$emit('action', this.params)
        },
        handle() {
            if (this.isOpenSideHandle) {
                this.handleOpen()
            } else {
                this.handleClose()
            }
            this.isOpenSideHandle = !this.isOpenSideHandle
        },
        handleClose() {
            document.getElementById('sidebar-body').style.display = 'none'
            document.getElementById('sidebar-close').style.display = 'none'
            document.getElementById('sidebar-open').style.display = 'inline-block'
            document.getElementById('sidebar-open').style.transform = 'rotate(360deg)'
        },
        handleOpen() {
            document.getElementById('sidebar-body').style.display = 'block'
            document.getElementById('sidebar-open').style.display = 'none'
            document.getElementById('sidebar-close').style.display = 'inline-block'
            document.getElementById('sidebar-close').style.transform = 'rotate(180deg)'
        },
        handleSecond() {
            if (this.isOpenSecondSideHandle) {
                this.handleOpenSecond()
            } else {
                this.handleCloseSecond()
            }
            this.isOpenSecondSideHandle = !this.isOpenSecondSideHandle
        },
        handleCloseSecond() {
            document.getElementById('sidebar-body--second').style.display = 'none'
            document.getElementById('sidebar-close--second').style.display = 'none'
            document.getElementById('sidebar-open--second').style.display = 'inline-block'
            document.getElementById('sidebar-open--second').style.transform = 'rotate(360deg)'
        },
        handleOpenSecond() {
            document.getElementById('sidebar-body--second').style.display = 'block'
            document.getElementById('sidebar-open--second').style.display = 'none'
            document.getElementById('sidebar-close--second').style.display = 'inline-block'
            document.getElementById('sidebar-close--second').style.transform = 'rotate(180deg)'
        },
        // 23区全選択・全解除
        checkAllArea() {
            this.params.city = []

            if (!this.isCheckAllArea) {
                this.areaList.forEach(city => {
                    this.params.city.push(city.id)
                });
            }

            this.isCheckAllArea = !this.isCheckAllArea
        }
    }
}
</script>

<style lang="scss" scoped>
.p-sidebar {
    width: 236px;
    border: 1px solid #ccc;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #fff;

    @media screen and (max-width: 1024px) {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    &__handle {
        display: none;

        @media screen and (max-width: 1024px) {
            display: block;
        }
    }


    &__header {
        overflow: hidden;
        margin: 1px 1px 0;
        padding: 14px 14px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: $secondary-color;
        color: #fef9ea;
        display: flex;
        justify-content: space-between;

        & > span {
            font-weight: bold;
            font-size: 13px;
        }
    }

    &__body {
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &__item {
        padding: 15px 10px 10px 15px;

        &--flex {
            padding-bottom: 0;
        }
    }

    &__close {
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &__itemTitle {
        text-decoration: none;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        padding-left: 10px;
        border-left: 5px solid #ccc;
        margin-bottom: 20px;
        display: inline-block;
        width: 100%;

        &--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__itemSelectLayout {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__layout {
        &--list {

            &--one {
                list-style: none;
                display: block;
                padding-left: 0;
            }

            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 0;
        }

        &--item {
            height: 30px;
            width: 50%;

            &--one {
                width: 100%;
                height: 30px;
            }

            @media screen and (max-width: 1024px) {
                width: 33%;
            }
        }
    }

    &__searchBtn {
        text-align: center;
        padding: 15px 10px 30px 10px;
    }
}

.searchBtn {
    display: inline-block;
    height: 45px;
    width: 90%;
    line-height: 30px;
    font-size: 14px;
}

.search-checkbox {

    transform: scale(1.3);

    &:hover {
        cursor: pointer;
    }

    & ~ label {
        padding: 4px 0 4px 10px;
        font-size: 13px;

        &:hover {
            cursor: pointer;
        }
    }
}

.search-select {
    width: 43%;
    height: 33px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    background-size: 7px auto;
    cursor: pointer;
    outline: none;

    // &:hover {
    //     border: 1px solid #0081cc;
    //     color: #0081cc;
    //     transition: all .4s;
    // }
}

.check-all {
    font-weight: bold;
}
</style>