export const LINE_BASE_URL     = 'http://line.me/R/msg/text/'
export const FACEBOOK_BASE_URL = 'https://www.facebook.com/share.php'
export const TWITTER_BASE_URL  = 'https://twitter.com/share'
export const MAIL_BASE_URL     = 'mailto:'


export default {

    createLineShareUrl(text, page_url) {
        return LINE_BASE_URL + '?' + text + page_url
    },

    createFacebookShareUrl(page_url) {
        return FACEBOOK_BASE_URL + '?u=' + page_url
    },

    createTwitterShareUrl(text, page_url) {
        return TWITTER_BASE_URL + '?text=' + text + '&url=' + page_url
    },

    createEmailShareUrl(city, text, page_url) {
        return MAIL_BASE_URL + '?subject=' + city + '&body=' + text + ' ' + page_url
    }

}