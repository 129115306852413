<template>
    <el-dialog
        :visible.sync="dialogVisible"
        @close="$emit('close')">
        <div style="display: flex;" slot="title">
            <img src="../../../assets/images/common/share.png" class="share-icon"><span>シェアする</span>
        </div>

        <div class="share-btn-group">
            <share-button
                type="line"
                :href="lineUrl"
                icon="fab fa-line"
            >LINE</share-button>

            <share-button
                type="twitter"
                :href="twitterUrl"
                icon="fab fa-twitter"
            >Twitter</share-button>

            <share-button
                type="facebook"
                :href="facebookUrl"
                icon="fab fa-facebook-f"
            >Facebook</share-button>

            <share-button
                type="email"
                :href="emailUrl"
                icon="fas fa-envelope"
            >Email</share-button>

        </div>

        <span slot="footer" class="dialog-footer">
            <el-button label-width="200" @click="$emit('close')">キャンセル</el-button>
        </span>
    </el-dialog>
</template>

<script>
import ShareButton from '@/components/molecules/buttons/ShareButton'
import Share from '@/utils/share'
import Property from '@/domain/entities/property/property'

export default {
    name: 'ShareModal',

    components: {
        ShareButton
    },

    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        property: {
            type: Object,
            required: true
        }
    },

    computed: {
        shareUrl() {
            return location.protocol + '//' + location.host + '/detail/' + this.property.data.id
        },
        lineUrl() {
            if (!this.isExistProperty()) { return; }
            return Share.createLineShareUrl(this.property.createShareText(), this.shareUrl)
        },
        twitterUrl() {
            if (!this.isExistProperty()) { return; }
            return Share.createTwitterShareUrl(this.property.createShareText(), this.shareUrl)
        },
        facebookUrl() {
            if (!this.isExistProperty()) { return; }
            return Share.createFacebookShareUrl(this.shareUrl)
        },
        emailUrl() {
            if (!this.isExistProperty()) { return; }
            return Share.createEmailShareUrl(this.property.data.city, this.property.createShareText(), this.shareUrl)
        }
    },

    methods: {
        isExistProperty() {
            return this.property instanceof Property
        }
    }
}
</script>

<style lang="scss">
.share-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
</style>