<template>
    <div>
        <Header />
        <div id="layout-portal">
            <router-view class="base-main"></router-view>
        </div>
    </div>
</template>

<script>
import Header from '@/components/organisms/headers/UserHeader'

export default {
  name: 'LayoutPortal',

  components: {
      Header
  }
}
</script>

<style lang="scss">
.firstview {
    margin-bottom: 20px;

    &__image {
        width: 100%;
        height: 57vw;
        max-height: 470px;
        background-image: url('../assets/images/top/firstview.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 93%;
    }
}

#layout-portal {
    width: 986px;
    margin: 0 auto;

    @media screen and (max-width: 1035px) {
        width: 100%;
    }
}
</style>