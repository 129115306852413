import store from '@/store/index'
import Cookies from 'js-cookie'

export default {

    async getItems() {
        return store.state.property.properties
    },

    // 検索条件データの保持
    saveSearchCriteria(params) {
        Cookies.set('portal_search_criteria', JSON.stringify(params), { expires: 1/12 })
    },

    // 検索条件を取得する
    fetchSearchCriteria() {
        return JSON.parse(Cookies.get('portal_search_criteria'))
    },

    // 検索条件を削除
    removeSearchCriteria() {
        Cookies.remove('portal_search_criteria')
    },

    // 検索条件を保持しているか確認する
    isCriteriaExists() {
        return Cookies.get('portal_search_criteria') != undefined
    },
}