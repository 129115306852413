<template>
    <div class="portal">
        <div class="portal__main">
            <div class="portal__left">

                <div class="portal__sortBlock">
                    <div>
                        <select v-model="params.sortValue" style="width: 120px; margin-right: 10px;" name="" id="" class="search-select">
                            <option :value="sort.value" v-for="(sort, index) in sortList" :key="index">{{ sort.name }}</option>
                        </select>

                        <select v-model="params.sort" style="width: 80px; margin-right: 10px;" name="" id="" class="search-select">
                            <option value="desc">▲</option>
                            <option value="asc">▼</option>
                        </select>
                    </div>
                    <base-button style="width: 100px;" @click="searchSort">並び替える</base-button>
                </div>

                <div class="results">
                    <div class="results__message"><span class="results__hits">{{ total }}</span> 件が該当しました</div>
                </div>

                <div class="propertyList">
                    <div>
                        <div class="propertyBlock" v-for="(property, index) in list" :key="index">
                            <admin-property-card :property="property" @changePublicStatus="changePublicStatus"></admin-property-card>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination
                        background
                        :page-size="20"
                        layout="prev, pager, next"
                        :total="total"
                        :current-page.sync="currentPage"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>

            <div class="portal_right">
                <portal-sidebar ref="sidebar" @action="search"/>
            </div>
        </div>

    </div>
</template>

<script>
import BaseButton from '@/components/atoms/buttons/BaseButton'
import AdminPropertyCard from '@/components/organisms/cards/AdminPropertyCard'
import PortalSidebar from '@/components/organisms/sidebar/PortalSidebar'
import { sortList } from '@/definitions/portal/sort_list'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';

export default {
    name: 'Property',

    components: {
        BaseButton,
        AdminPropertyCard,
        PortalSidebar
    },

    data() {
        return {
            params: {
                city: [],
                minPrice: '',
                maxPrice: '',
                datePre: '',
                datePost: '',
                minTotalFloorUnitPrice: '',
                maxTotalFloorUnitPrice: '',
                minOccupiedUnitPrice: '',
                maxOccupiedUnitPrice: '',
                minLandUnitPrice: '',
                maxLandUnitPrice: '',
                usage: '',
                maxGross: '',
                integration_over: '',
                minTotalFloorArea: '',
                maxTotalFloorArea: '',
                minOccupiedArea: '',
                maxOccupiedArea: '',
                inheritance: '',
                market_under: '',
                minGross: '',
                minLife: '',
                maxLife: '',
                minLoan: '',
                maxLoan: '',
                minCompressibility: '',
                maxCompressibility: '',
                right: [],
                detailed: [],
                page: 1,
                sortValue: 'acquisition_date',
                sort: 'desc',
            },
            currentPage: 1
        }
    },

    computed: {
        ...mapState({
            list: state => state.property.properties,
            total: state => state.property.total,
            apiStatus: state => state.property.apiStatus
        }),
        sortList() {
            return sortList
        }
    },

    async mounted() {
        await this.search(this.params);
    },

    methods: {
        async searchSort() {
            await this.search(this.params)
        },
        async search(params) {
            let loadingInstance = Loading.service({
                lock: true,
                text: '物件を取得中です',
                spinner: 'el-icon-loading',
            });

            try {
                params['public'] = 1
                await this.$store.dispatch('property/getList', params)
            } finally {
                this.$nextTick(() => { loadingInstance.close(); });
                scrollTo(0, 0)
            }
        },
        // 公開ステータス
        async changePublicStatus(property) {
            await this.$store.dispatch('property/changePublicStatus', property)

            if (this.apiStatus) {
                Message({ message: '公開設定を変更しました', type: 'success' });
                return
            }

            Message({ message: 'システムエラーが発生しました', type: 'error' });
        },
        // ペジネーションイベント
        async handleCurrentChange(page) {
            await this.$refs.sidebar.actionSearch(page)
            this.currentPage = page
        },
    }
}
</script>


<style lang="scss" scoped>
.pager {
    text-align: right;
    padding: 10px 0 30px 0;

    @media screen and (max-width: 414px) {
        text-align: center;
    }
}

.propertyBlock {
    margin-bottom: 20px;
}

.results {
    border-bottom: 1px solid #B7B7B7;
    margin: 0 0 50px 0;
    padding: 0 0 5px 0;

    @media screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
    }

    &__message {
        font-weight: bold;
    }

    &__hits {
        color: $base-color;
        font-weight: bold;
        font-size: 30px;
    }
}

.sp-searchBtn {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 9999;

    & > i {
        font-size: 18px;
    }

    @media screen and (max-width: 414px) {
        display: block
    }
}
</style>