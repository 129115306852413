import Vue from 'vue'
import VueRouter from 'vue-router'

// ------- Layout ---------
import LayoutNormal from '@/layouts/NormalLayout'
import LayoutTop from '@/layouts/LayoutTop'
import LayoutPortal from '@/layouts/LayoutPortal'
import LayoutAuth from '@/layouts/AuthLayout'
import LayoutAdmin from '@/layouts/LayoutAdmin'

// ------- Page ---------
import PortalIndex from '@/pages/portal/index'
import Detail from '@/pages/portal/Detail'
import FavoriteIndex from '@/pages/portal/FavoriteIndex'
import Login from '@/pages/user/auth/Login'
import Activate from '@/pages/user/auth/Activate'
import ForgetPassword from  '@/pages/user/auth/ForgetPassword'
import ReRegisterPassword from '@/pages/user/auth/ReRegisterPassword'
import Profile from '@/pages/user/Profile'

import CsvUpload from '@/pages/admin/CsvUpload'
import AdminProperty from '@/pages/admin/Property'
import DocumentReqestForm from '@/pages/documentRequest/Form'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutTop,
    children: [
      {
        path: '',
        name: 'portalIndex',
        component: PortalIndex
      },
    ],
  },
  {
    path: '/',
    component: LayoutPortal,
    children: [
      {
        path: 'detail/:id',
        name: 'detail',
        component: Detail
      },
      {
        path: 'favorite',
        name: 'favorite',
        component: FavoriteIndex
      }
    ]
  },
  {
    path: '/user',
    component: LayoutNormal,
    children: [
      {
        path: 'auth',
        component: LayoutAuth,
        children: [
          {
            path: 'login',
            name: 'login',
            component: Login
          },
          {
            path: 'activate',
            name: 'activate',
            component: Activate
          },
          {
            path: 'forget',
            name: 'forget',
            component: ForgetPassword
          },
          {
            path: 'updatePassword',
            name: 'updatePassword',
            component: ReRegisterPassword
          }
        ]
      },
      {
        path: ':username',
        name: 'Profile',
        component: Profile
      }
    ]
  },
  {
    path: '/admin',
    component: LayoutAdmin,
    children: [
      {
        path: 'property',
        name: 'adminProperty',
        component: AdminProperty
      },
      {
        path: 'csvUpload',
        name: 'csvUpload',
        component: CsvUpload
      }
    ]
  },
  {
    path: '/document-request',
    component: LayoutPortal,
    children: [
      {
        path: 'form/:propertyId',
        name: 'documentRequestForm',
        component: DocumentReqestForm
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router