export const sortList = [
    {
        name: '日時',
        value: 'acquisition_date'
    },
    {
        name: '価格',
        value: 'price'
    },
    {
        name: '延床坪単価',
        value: 'total_floor_area_price_per_unit'
    },
    {
        name: '専有坪単価',
        value: 'occupied_area_price_per_unit'
    },
    {
        name: '土地坪単価',
        value: 'land_tsubo_price'
    },
    {
        name: '延床面積',
        value: 'total_floor_area'
    },
    {
        name: '専有面積',
        value: 'occupied_area'
    },
    {
        name: '残存耐用年数',
        value: 'remain_useful_life'
    }
]