<template>
    <div>
        <Header />
        <router-view id="auth-layout" class="base-main"></router-view>
    </div>
</template>

<script>
import Header from '@/components/organisms/headers/UserHeader'

export default {
    name: 'LayoutAdmin',

    components: {
        Header
    },

    computed: {
        isAdmin() {
            return this.$store.getters['auth/isAdmin']
        }
    },

    mounted() {
        if (!this.isAdmin) {
            this.$router.push({ name: 'portalIndex' })
        }
    }
}
</script>

<style lang="scss" scoped>
#auth-layout {
    width: 986px;
    margin: 0 auto;

    @media screen and (max-width: 1035px) {
        width: 100%;
    }
}
</style>