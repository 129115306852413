<template>
    <div class="favoriteList">
        <div class="results">
            <div class="results__message">お気に入り物件<span class="results__hits"> {{ total }} </span>件</div>
        </div>

        <div class="propertyList">
            <div>
                <div class="propertyBlock" v-for="(property, index) in list" :key="index">
                    <property-card :property="property" @favorite="handleFavorite" @share="handleShare"></property-card>
                </div>
            </div>
        </div>
        <div class="pager">
            <el-pagination
                background
                :page-size="20"
                layout="prev, pager, next"
                :total="total"
                :current-page.sync="currentPage"
                @current-change="handleCurrentChange"
            ></el-pagination>
        </div>
        <share-modal
            :dialogVisible="isShareModal"
            :property="shareProperty"
            @close="closeShareModal"
        />
    </div>
</template>

<script>
import PropertyCard from '@/components/organisms/cards/PropertyCard'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import ShareModal from '@/components/organisms/modals/ShareModal'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';

export default {
    name: 'FavoriteIndex',

    components: {
        PropertyCard,
        ShareModal
    },

    data() {
        return {
            currentPage: 1,
            shareProperty: {},
            isShareModal: false
        }
    },

    computed: {
        ...mapState({
            list: state => state.property.properties,
            total: state => state.property.total,
            apiStatus: state => state.property.apiStatus,
            errors: state => state.property.errors
        })
    },

    async mounted() {
        // 認証していない場合はトップページに遷移
        if (!AuthRepository.isLoggedIn()) {
            this.$router.push({ name: 'portalIndex' })
            return
        }

        this.fetchFavoriteList()
    },

    methods: {
        // お気に入り一覧取得
        async fetchFavoriteList(page = null) {
            let loadingInstance = Loading.service({
                lock: true,
                text: 'お気に入り物件を取得中です',
                spinner: 'el-icon-loading',
            });

            try {
                await this.$store.dispatch('property/fetchFavoriteList', page)
            } finally {
                this.$nextTick(() => { loadingInstance.close(); });
                scrollTo(0, 0)
            }
        },
        // お気に入り処理
        async handleFavorite(property) {

            if (!confirm('お気に入りから削除してもよろしいでしょうか')) {
                return
            }

            // リストからイベント対象の物件を取得する
            const findProperty = (element) => element.data.id === property.data.id;
            const index = this.list.findIndex(findProperty)

            try {
                this.loading = true

                // お気に入り一覧画面では、お気に入り解除する物件しか存在しないためお気に入り登録はエラーとする
                if (property.isFavorited()) {
                    this.list[index].data.favorites = false
                    await this.$store.dispatch('property/removeFavorite', property.data.id)
                } else {
                    throw new Error('お気に入り削除に失敗しました')
                }

                await this.fetchFavoriteList(this.currentPage)
            } catch (e) {
                this.list[index].data.favorites = true
                Message({ message: 'お気に入り削除に失敗しました', type: 'success' });
            } finally {
                this.loading = false
            }
        },
        // ページネーション イベント
        async handleCurrentChange(page) {
            await this.fetchFavoriteList(page)
            this.currentPage = page
        },
        // シェア機能
        async handleShare(property) {
            this.shareProperty = property
            this.isShareModal = true
        },
        closeShareModal() {
            this.isShareModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
.favoriteList {
    width: 800px !important;
    margin: 80px auto;

    @media screen and (max-width: 414px) {
        width: 100% !important;
    }
}
.pager {
    text-align: right;
    padding: 10px 0 30px 0;

    @media screen and (max-width: 414px) {
        text-align: center;
    }
}

.results {
    border-bottom: 1px solid #B7B7B7;
    margin: 0 0 50px 0;
    padding: 0 0 5px 0;

    @media screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
    }

    &__message {
        font-weight: bold;
    }

    &__hits {
        color: #F77C00;
        font-weight: bold;
        font-size: 30px;
    }
}
</style>