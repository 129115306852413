<template>
    <div class="login">

        <div class="signin signin--login">
            <validation-observer tag='div' ref="login" v-slot="{ invalid }">
                <h4 class="signin__title">ログイン</h4>

                <p v-if="errors.unauthorized" class="errors">{{ errors.unauthorized }}</p>

                <base-input-field name="email" label="メールアドレス" rules="required|email|max:254" :apiErrors="errors" @update:field="profile.email = $event" required />
                <base-input-field type="password" name="password" label="パスワード" rules="required|max:20" :apiErrors="errors" @update:field="profile.password = $event" required />

                <div>
                    <base-button @click="loginWithEmail('email')" class="signin__login" :disabled="invalid">ログイン</base-button>

                    <div class="login-menu">
                        <div class="login-menu--register">
                            <a :href="accountUrl" target="_blank" class="login-menu__link">新規登録</a>
                        </div>
                        <div class="login-menu--forgot-password">
                            <span style="margin-right: 3px;">ログインできない方は</span><router-link :to="{ name: 'forget' }" class="login-menu__link">こちら</router-link>
                        </div>
                    </div>

                    <div class="divider"><span>または</span></div>

                    <base-button @click="loginWithProvider('google')" class="signin__login base-btn--google"><img style="width: 20px; margin-right: 10px;" src="../../../assets/images/auth/google.png" alt="">Googleでログインする</base-button>
                    <base-button @click="loginWithProvider('facebook')" class="signin__login base-btn--facebook"><img style="width: 20px; margin-right: 10px;" src="../../../assets/images/auth/facebook.png" alt="">Facebookでログインする</base-button>
                </div>

            </validation-observer>
        </div>
    </div>
</template>

<script>
import BaseInputField from '@/components/atoms/inputs/BaseInputField'
import BaseButton from '@/components/atoms/buttons/BaseButton'
import { ValidationObserver } from "vee-validate";
import { mapState } from 'vuex'
import { Loading } from 'element-ui';

export default {
    name: 'Login',

    components: {
        ValidationObserver,
        BaseInputField,
        BaseButton
    },

    data() {
        return {
            profile: {
                email: '',
                password: ''
            }
        }
    },

    computed: {
        ...mapState({
            errors: state => state.auth.errors,
            apiStatus: state => state.auth.apiStatus,
            redirectUrl: state => state.auth.redirectUrl,
            snsProfile: state => state.auth.profile
        }),
        accountUrl() {
            return process.env.VUE_APP_ACCOUNT_URL + '?via=ENDREACH_PORTAL'
        }
    },

    methods: {
        // Eメールログイン
        async loginWithEmail() {
            let loadingInstance = Loading.service({
                lock: true,
                text: 'ログイン中です',
                spinner: 'el-icon-loading',
            });

            try {
                const response = await this.$store.dispatch('auth/loginWithEmail', this.profile)

                // リダイレクト処理
                if (response.data.redirect_url != null) {
                    window.open(response.data.redirect_url)
                    return
                }
            } finally {
                this.$nextTick(() => {
                    loadingInstance.close();
                });
            }

            if (this.apiStatus) {
                this.$router.push({ name: 'portalIndex' })
            }
        },
        // SNSログイン
        async loginWithProvider(provider) {

            let loadingInstance = Loading.service({
                lock: true,
                text: 'ログイン中です',
                spinner: 'el-icon-loading',
            });

            try {
                const response = await this.$store.dispatch('auth/loginWithProvider', { viewContext: this, provider: provider })

                // リダイレクト処理
                if (response.data.redirect_url != null) {
                    await this.$store.dispatch('auth/saveTemporaryUser', this.snsProfile)
                    window.open(response.data.redirect_url)
                    return
                }

            } finally {
                this.$nextTick(() => { loadingInstance.close(); });
            }

            if (this.apiStatus) {
                this.$router.push({ name: 'portalIndex' })
            }
        }
    }
}
</script>