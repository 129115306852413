import Vue from 'vue'
import Vuex from 'vuex'

import property from './modules/property'
import auth from './modules/auth'
import documentRequest from './modules/documentRequest'


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        property,
        auth,
        documentRequest
    }
})

export default store