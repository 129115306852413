/**
 * 数値を日本の価格表示に変更
 *
 * @param {Number} price
 * @return {String}
 */
export function changeJpPrice(price) {

    // 千円以下は四捨五入する
    const keta = ['', '万', '億', '兆', '京'];

    let prices = String(price).replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,").split(",").reverse();
    let data = '';
    for( let i = 0; i < prices.length; i++ ){
        if(!prices[i].match(/^[0]+$/)){
            data = prices[i].replace(/^[0]+/g, "") + keta[i] + data
        }
    }

    return data
}

/**
 * 金額にカンマをつける
 */
export function addConma(price) {
    return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}