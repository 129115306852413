<template>
    <div class="csv-upload">
        <div class="csv-block">
            <div class="csv-block__header">
                <div class="csv-block__title">
                    <span>CSVファイルをアップロード</span>
                </div>
            </div>
            <div class="csv-block__body">
                <div class="csv-block__container">
                    <div class="csv-block__left">
                        <span>CSVファイルを選択</span>
                    </div>
                    <div class="csv-block__right">
                        <base-file-input class="csv-block__file-select" accept=".csv" @change="onFileChange" />
                        <p v-if="errors.message" class="errors">{{ errors.message }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseFileInput from '@/components/atoms/inputs/BaseFileInput'
import { mapState } from 'vuex'
import { Loading } from 'element-ui';
import { Message } from 'element-ui'

export default {
    name: 'CsvUpload',

    components: {
        BaseFileInput
    },

    data() {
        return {
            csv: null
        }
    },

    computed: {
        ...mapState({
            apiStatus: state => state.property.apiStatus,
            errors: state => state.property.errors
        }),
        isSetCsv() {
            return this.csv !== null
        }
    },

    methods: {
        // ファイルの選択
        onFileChange(e) {
            this.csv = e.target.files[0]

            if (this.isSetCsv) {
                this.upload()
            }
        },
        // アップロード
        async upload() {

            let loadingInstance = Loading.service({
                lock: true,
                text: 'CSVアップロード中です',
                spinner: 'el-icon-loading',
            });

            try {
                await this.$store.dispatch('property/uploadPropertyCsv', this.csv)

                if (this.apiStatus) {
                    Message({ message: 'CSVアップロードが完了しました', type: 'success' });
                    return
                }

            } finally {
                this.$nextTick(() => { loadingInstance.close(); });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.csv-upload {
    margin-top: 100px !important;
}

.csv-block {
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 14px;

    @media screen and (max-width: 414px) {
        margin: 0 auto;
        width: 95%;
    }

    &__header {
        border-bottom: 1px solid #ccc;
        padding: 10px 20px;
    }

    &__body {
        padding: 20px;
    }

    &__container {
        display: flex;
    }

    &__left {
        margin-top: 10px;
    }

    &__right {
        margin-left: 50px;

        @media screen and (max-width: 414px) {
            margin-left: 20px;
        }
    }

    &__file-select {
        margin-bottom: 15px;
    }
}

.upload-btn {
    background-color: #437EC4;
    color: #fff;
    border: none;

    &:hover {
        background-color: #437EC4;
        opacity: 0.8;
        color: #fff;
    }
}
</style>