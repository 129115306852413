<template>
    <div class="portal">
        <div class="portal__main">

            <div class="portal__left">

                <div class="portal__sortBlock">

                    <div>
                        <select v-model="params.sortValue" style="width: 120px; margin-right: 10px;" class="search-select">
                            <option :value="sort.value" v-for="(sort, index) in sortList" :key="index">{{ sort.name }}</option>
                        </select>

                        <select v-model="params.sort" style="width: 80px; margin-right: 10px;" class="search-select">
                            <option value="desc">▼</option>
                            <option value="asc">▲</option>
                        </select>
                    </div>
                    <base-button style="width: 100px;" @click="searchSort">並び替える</base-button>

                </div>

                <div class="results">
                    <div class="results__message"><span class="results__hits">{{ total }}</span> 件が該当しました</div>
                </div>

                <div class="propertyList">
                    <div>
                        <div class="propertyBlock" v-for="(property, index) in list" :key="index">
                            <property-card :property="property" @favorite="handleFavorite" @share="handleShare" @login="openLoginModal"></property-card>
                        </div>
                    </div>
                </div>

                <div class="pager">
                    <el-pagination
                        background
                        :page-size="20"
                        layout="prev, pager, next"
                        :pager-count="5"
                        :total="total"
                        :current-page.sync="currentPage"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </div>

            <div class="portal__right">
                <portal-sidebar ref="sidebar" @action="search" :searchCriteria="params"/>
            </div>
        </div>

        <login-modal
            :dialogVisible="isLoginModal"
            @close="closeLoginModal"
            @login="$router.push({ name: 'login' })"
        />

        <share-modal
            :dialogVisible="isShareModal"
            :property="shareProperty"
            @close="closeShareModal"
        />

    </div>
</template>

<script>
import BaseButton from '@/components/atoms/buttons/BaseButton'
import PropertyCard from '@/components/organisms/cards/PropertyCard'
import PortalSidebar from '@/components/organisms/sidebar/PortalSidebar'
import LoginModal from '@/components/organisms/modals/LoginModal'
import ShareModal from '@/components/organisms/modals/ShareModal'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import PropertyRepository from '@/infrastructure/repositories/PropertyRepository'
import { sortList } from '@/definitions/portal/sort_list'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';

export default {
    name: 'PortalIndex',

    components: {
        BaseButton,
        PropertyCard,
        PortalSidebar,
        LoginModal,
        ShareModal
    },

    data() {
        return {
            params: {
                city: [],
                datePre: '',
                datePost: '',
                minPrice: '',
                maxPrice: '',
                minTotalFloorUnitPrice: '',
                maxTotalFloorUnitPrice: '',
                minOccupiedUnitPrice: '',
                maxOccupiedUnitPrice: '',
                minLandUnitPrice: '',
                maxLandUnitPrice: '',
                usage: [],
                integration_over: '',
                minTotalFloorArea: '',
                maxTotalFloorArea: '',
                minOccupiedArea: '',
                maxOccupiedArea: '',
                maxGross: '',
                minGross: '',
                market_under: '',
                inheritance: '',
                minLife: '',
                maxLife: '',
                minLoan: '',
                maxLoan: '',
                minCompressibility: '',
                maxCompressibility: '',
                right: [],
                detailed: [],
                page: 1,
                sortValue: 'acquisition_date',
                sort: 'desc',
            },
            currentPage: 1,
            isLoginModal: false,
            isShareModal: false,
            shareProperty: {},
        }
    },

    computed: {
        ...mapState({
            list: state => state.property.properties,
            total: state => state.property.total,
            apiStatus: state => state.property.apiStatus
        }),
        sortList() {
            return sortList
        }
    },

    async mounted() {
        // 検索条件が保持されていた場合、その検索条件を適応する
        if (PropertyRepository.isCriteriaExists()) {
            this.params = PropertyRepository.fetchSearchCriteria()
            PropertyRepository.removeSearchCriteria()
        }

        await this.search(this.params)
    },

    methods: {
        async searchSort() {
            await this.search(this.params)
        },
        // 物件一覧取得処理
        async search(params) {
            let loadingInstance = Loading.service({
                lock: true,
                text: '物件を取得中です',
                spinner: 'el-icon-loading',
            });

            // 検索条件を保存する
            PropertyRepository.saveSearchCriteria(params)

            try {
                await this.$store.dispatch('property/getList', params)
            } finally {
                this.$nextTick(() => { loadingInstance.close(); });
                scrollTo(0, 0)
            }
        },
        // ペジネーションイベント
        async handleCurrentChange(page) {
            await this.$refs.sidebar.actionSearch(page)
            this.currentPage = page
        },
        // ログイン遷移モーダルの表示
        openLoginModal() {
            this.isLoginModal = true
        },
        closeLoginModal() {
            this.isLoginModal = false
        },
        // お気に入り処理
        async handleFavorite(property) {

            if (!AuthRepository.isLoggedIn()) {
                this.openLoginModal()
                return
            }

            const findProperty = (element) => element.data.id === property.data.id;
            const index = this.list.findIndex(findProperty)

            if (property.isFavorited()) {
                this.list[index].data.favorites = false
                await this.$store.dispatch('property/removeFavorite', property.data.id)
                this.removeFavorite(index)
            } else {
                this.list[index].data.favorites = true
                await this.$store.dispatch('property/addFavorite', property.data.id)
                this.addFavorite(index)
            }
        },
        addFavorite(index) {
            if (this.apiStatus) {
                Message({ message: 'お気に入りに登録しました', type: 'success' });
                return
            }
            // 失敗した場合
            Message({ message: 'お気に入りの登録に失敗しました', type: 'error' });
            this.list[index].data.favorites = false
        },
        removeFavorite(index) {
            if (this.apiStatus) {
                Message({ message: 'お気に入りから削除しました', type: 'success' });
                return
            }
            // 失敗した場合
            Message({ message: 'お気に入りの登録に失敗しました', type: 'error' });
            this.list[index].data.favorites = true
        },
        // シェア機能
        async handleShare(property) {
            this.shareProperty = property
            this.isShareModal = true
        },
        closeShareModal() {
            this.isShareModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
.pager {
    text-align: right;
    padding: 10px 0 30px 0;

    @media screen and (max-width: 414px) {
        text-align: center;
    }
}

.propertyBlock {
    margin-bottom: 20px;
}

.results {
    border-bottom: 1px solid #B7B7B7;
    margin: 0 0 50px 0;
    padding: 0 0 5px 0;

    @media screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
    }

    &__message {
        font-weight: bold;
    }

    &__hits {
        color: $base-color;
        font-weight: bold;
        font-size: 30px;
    }
}

.sp-searchBtn {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    z-index: 9999;

    & > i {
        font-size: 18px;
    }

    @media screen and (max-width: 414px) {
        display: block
    }
}

.sp-sidebar {
    display: none;
}
</style>