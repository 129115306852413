export const PriceList = [
    {
        name: '300万円',
        value: 3000000
    },
    {
        name: '500万円',
        value: 5000000
    },
    {
        name: '800万円',
        value: 8000000
    },
    {
        name: '1000万円',
        value: 10000000
    },
    {
        name: '2000万円',
        value: 20000000
    },
    {
        name: '3000万円',
        value: 30000000
    },
    {
        name: '4000万円',
        value: 40000000
    },
    {
        name: '5000万円',
        value: 50000000
    },
    {
        name: '6000万円',
        value: 60000000
    },
    {
        name: '7000万円',
        value: 70000000
    },
    {
        name: '8000万円',
        value: 80000000
    },
    {
        name: '9000万円',
        value: 90000000
    },
    {
        name: '1億円',
        value: 100000000
    },
    {
        name: '2億円',
        value: 200000000
    },
    {
        name: '3億円',
        value: 300000000
    },
]