import { changeJpPrice } from '@/utils/price'

export default class {

    BUILDING       = "一棟"
    SECTION        = "区分"
    DETACHED_HOUSE = '戸建'

    constructor(data) {
        this.data = data
    }

    // お気に入りの判定
    isFavorited() {
        return this.data.favorites
    }

    /**
     * 残存耐用年数
     * 超過していた場合は「超過年数」と追記する
     */
    remainingUsefulLife() {
        return this.data.remaining_useful_life < 0 ?
            `${this.data.remaining_useful_life}年 (超過)` :
            `${this.data.remaining_useful_life}年`
    }

    /**
     * 賃料
     */
    rentPrice() {
        return this.data.annualFullRentIncome == null ?
            null :
            Math.floor(this.data.annualFullRentIncome / 12)
    }

    // 建物タイプ
    structureUseType() {
        if (this.data.buildingUse.includes(this.BUILDING)) {
            return this.BUILDING
        }
        else if (this.data.buildingUse.includes(this.SECTION)) {
            return this.SECTION
        }
        else if (this.data.buildingUse.includes(this.DETACHED_HOUSE)) {
            return this.DETACHED_HOUSE
        }
        else {
            return ''
        }
    }

    // メッセージ用のテキスト生成
    createShareText() {
        const city = this.data.city
        const station = this.data.station.name
        const distance = this.data.station.distance + 'm（徒歩' + this.data.station.walk_minutes + "分）"
        const use = this.structureUseType()
        const price = changeJpPrice(this.data.price) + '円'
        const landAreaText = this.data.landArea + '㎡'
        const totalFloorArea = this.data.totalFloorArea + '㎡'
        const totalFloorTsuboPrice = changeJpPrice((this.data.price / (this.data.totalFloorArea / 3.3)).toFixed()) + '円'
        const occupiedArea = this.data.occupiedArea + '㎡'
        const occupiedTsuboPrice = changeJpPrice((this.data.price / (this.data.occupiedArea / 3.3)).toFixed()) + '円'

        // 江戸川区、最寄駅：一之江、880m（徒歩11分）、 戸建、価格 5790万円、土地面積 100㎡、延床面積 118.48㎡

        // 区分の場合
        if (this.structureUseType() === this.SECTION) {
            return city + '、 ' +
                '最寄駅：' + station + '、' +
                distance + '、' +
                use + '、' +
                '価格：' + price + '、' +
                '専有面積：' + occupiedArea + '、' +
                '専有坪単価：' + occupiedTsuboPrice + '、'
        }

        return city + '、 ' +
                '最寄駅：' + station + '、' +
                distance + '、' +
                use + '、' +
                '価格：' + price + '、' +
                '土地面積：' + landAreaText + '、' +
                '延床面積：' + totalFloorArea + '、' +
                '延床坪単価：' + totalFloorTsuboPrice + '、'
    }
}