import APIClient from '@/infrastructure/network/APIClient'

export default {

    async fetchItems(params) {

        const payload = {
            city: params.city,
            date_pre: params.datePre,
            date_post: params.datePost,
            min_price: params.minPrice,
            max_price: params.maxPrice,
            min_total_floor_unit_price: params.minTotalFloorUnitPrice,
            max_total_floor_unit_price: params.maxTotalFloorUnitPrice,
            min_occupied_unit_price: params.minOccupiedUnitPrice,
            max_occupied_unit_price: params.maxOccupiedUnitPrice,
            min_land_unit_price: params.minLandUnitPrice,
            max_land_unit_price: params.maxLandUnitPrice,
            usage: params.usage,
            max_gross: params.maxGross,
            min_gross: params.minGross,
            market_under: params.market_under,
            inheritance: params.inheritance,
            min_life: params.minLife,
            max_life: params.maxLife,
            min_loan: params.minLoan,
            max_loan: params.maxLoan,
            min_compressibility: params.minCompressibility,
            max_compressibility: params.maxCompressibility,
            integration_over: params.integration_over,
            min_total_floor_area: params.minTotalFloorArea,
            max_total_floor_area: params.maxTotalFloorArea,
            min_occupied_area: params.minOccupiedArea,
            max_occupied_area: params.maxOccupiedArea,
            right: params.right,
            detailed: params.detailed,
            page: params.page,
            sortValue: params.sortValue,
            sort: params.sort,
            public: params.public || 0
        }

        return await APIClient.get('/endreachPortal/property/list', {
            params: payload
        })
            .then(response => response)
            .catch(error => error)
    },

    // IDから物件を取得する
    async fetchItemById(id) {
        return await APIClient.get(`/endreachPortal/property/${id}`)
            .then(response => response)
            .catch(error => error.response)
    },

    async fetchFavoriteItems(page) {
        const payload = {
            page: page
        }

        return await APIClient.get('/endreachPortal/property/favorites', {
            params: payload
        })
            .then(response => response)
            .catch(error => error.response)
    },

    // お気に入り登録
    async addFavorite(propertyId) {
        return await APIClient.post(`/endreachPortal/property/${propertyId}/favorite`)
            .then(response => response)
            .catch(error => error.response)
    },

    // お気に入りから外す
    async removeFavorite(propertyId) {
        return await APIClient.delete(`/endreachPortal/property/${propertyId}/favorite`)
            .then(response => response)
            .catch(error => error.response)
    },

    // ユーザーのクリックイベントのロギング
    async logging(propertyId) {
        return await APIClient.post(`/endreachPortal/property/${propertyId}/logging`)
            .then(response => response)
            .catch(error => error.response)
    },

    // 物件CSVのアップロード
    async uploadPropertyCsv(csv) {
        let formData = new FormData()
        formData.append('file', csv)

        return await APIClient.post('/endreachPortal/admin/property/upload', formData)
            .then(response => response)
            .catch(error => error.response)
    },

    // 公開ステータスの更新
    async changePublicStatus(id) {
        return await APIClient.put(`/endreachPortal/admin/property/${id}/change/publicStatus`)
            .then(response => response)
            .catch(error => error.response)
    }
}