export const CompressibilityList = [
    {
        name: '10%',
        value: 0.1
    },
    {
        name: '20%',
        value: 0.2
    },
    {
        name: '30%',
        value: 0.3
    },
    {
        name: '40%',
        value: 0.4
    },
    {
        name: '50%',
        value: 0.5
    },
    {
        name: '60%',
        value: 0.6
    },
    {
        name: '70%',
        value: 0.7
    },
    {
        name: '80%',
        value: 0.8
    },
    {
        name: '90%',
        value: 0.9
    },
    {
        name: '100%',
        value: 1.0
    }
]