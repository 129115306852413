import Property from '@/infrastructure/network/api/Property'
import { OK, CREATED, UNAUTHENTICATED } from '@/utils/const'
import PropertyEntity from '@/domain/entities/property/property.js'
import router from '@/router/index'
import { Message } from 'element-ui'

const state = {
    properties: [],
    property: null,
    total: null,
    apiStatus: null,
    errors: []
}

const mutations = {
    SET_API_STATUS(state, status) {
        state.apiStatus = status
    },

    SET_PROPERTIES(state, properties) {
        let entity = []

        properties.forEach(property => {
            entity.push(new PropertyEntity(property))
        });

        state.properties = entity
    },

    SET_PROPERTY(state, property) {
        state.property = new PropertyEntity(property)
    },

    SET_TOTAL(state, total) {
        state.total = total
    },

    SET_ERRORS(state, errors) {
        state.errors = errors
    }
}

const actions = {

    // 物件一覧取得
    async getList(context, data) {
        context.commit('SET_PROPERTIES', [])
        context.commit('SET_API_STATUS', null)
        context.commit('SET_TOTAL', null)

        const response = await Property.fetchItems(data)

        if (response.status === OK) {
            context.commit('SET_PROPERTIES', response.data.data)
            context.commit('SET_TOTAL', response.data.total)
            context.commit('SET_API_STATUS', true)
            return
        }

        context.commit('SET_API_STATUS', false)
    },

    // 物件情報を取得する
    async fetchProperty(context, id) {
        context.commit('SET_API_STATUS', null)

        // 物件を取得
        const response = await Property.fetchItemById(id)

        if (response.status === OK) {
            context.commit('SET_PROPERTY', response.data)
            context.commit('SET_API_STATUS', true)
            return
        }

        context.commit('SET_API_STATUS', false)
    },

    // お気に入りから外す
    async removeFavorite(context, propertyId) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', [])

        // お気に入り登録する
        const response = await Property.removeFavorite(propertyId)

        if (response.status === OK) {
            context.commit('SET_API_STATUS', true)
            return
        }

        // 認証ハンドラー
        await context.dispatch('handleAuthenticate', response)

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // お気に入りに登録する
    async addFavorite(context, propertyId) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', [])

        // お気に入り登録する
        const response = await Property.addFavorite(propertyId)

        if (response.status === OK) {
            context.commit('SET_API_STATUS', true)
            return
        }

        // 認証ハンドラー
        await context.dispatch('handleAuthenticate', response)

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // お気に入り物件の取得
    async fetchFavoriteList(context, page) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_TOTAL', null)
        context.commit('SET_ERRORS', [])

        // お気に入り一覧取得
        const response = await Property.fetchFavoriteItems(page)

        if (response.status === OK) {
            context.commit('SET_PROPERTIES', response.data.data)
            context.commit('SET_TOTAL', response.data.total)
            return
        }

        // 認証ハンドラー
        await context.dispatch('handleAuthenticate', response)

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // 物件CSVのアップロード
    async uploadPropertyCsv(context, csv) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', [])

        // アップロードする
        const response = await Property.uploadPropertyCsv(csv)

        // 成功
        if (response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        // 認証ハンドラー
        await context.dispatch('handleAuthenticate', response)

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // 公開ステータスの更新
    async changePublicStatus(context, property) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', [])

        // 公開ステータスの更新
        const response = await Property.changePublicStatus(property.data.id)

        // 成功
        if (response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        // 認証ハンドラー
        await context.dispatch('handleAuthenticate', response)

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // ユーザーのクリックイベントのロギング処理
    async logging(context, propertyId) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', [])

        // ロギング処理
        const response = await Property.logging(propertyId)

        // 成功
        if (response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    },

    // レスポンスの処理
    async handleAuthenticate(context, response) {

        // 認証エラー
        if (response.status === UNAUTHENTICATED) {
            context.commit('SET_API_STATUS', null)
            Message({ message: 'ログインが切れました。再度ログインし直してください', type: 'error' });

            setTimeout(() => {
                router.push({ name: 'login' })
            }, 3000);
            return
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}