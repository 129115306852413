<template>
    <div class="detail">
        <property-card
            :property="property"
            @favorite="handleFavorite"
            @share="handleShare"
            @login="openLoginModal"
        />

        <login-modal
            :dialogVisible="isLoginModal"
            @close="closeLoginModal"
            @login="$router.push({ name: 'login' })"
        />

        <share-modal
            :dialogVisible="isShareModal"
            :property="property"
            @close="closeShareModal"
        />
    </div>
</template>

<script>
import PropertyCard from '@/components/organisms/cards/PropertyCard'
import LoginModal from '@/components/organisms/modals/LoginModal'
import ShareModal from '@/components/organisms/modals/ShareModal'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
    name: 'Detail',

    components: {
        PropertyCard,
        LoginModal,
        ShareModal
    },

    data() {
        return {
            isLoginModal: false,
            isShareModal: false
        }
    },

    async mounted() {
        await this.fetchProperty()
    },

    computed: {
        ...mapState({
            apiStatus: state => state.property.apiStatus,
            property: state => state.property.property
        }),
        id() {
            return this.$route.params.id
        }
    },

    methods: {
        async fetchProperty() {
            await this.$store.dispatch('property/fetchProperty', this.id)
        },
        async handleFavorite() {
            if (!AuthRepository.isLoggedIn()) {
                this.openLoginModal()
                return
            }

            if (this.property.isFavorited()) {
                this.property.data.favorites = false
                await this.$store.dispatch('property/removeFavorite', this.property.data.id)
                this.removeFavorite()
            } else {
                this.property.data.favorites = true
                await this.$store.dispatch('property/addFavorite', this.property.data.id)
                this.addFavorite()
            }
        },
        addFavorite() {
            if (this.apiStatus) {
                Message({ message: 'お気に入りに登録しました', type: 'success' });
                return
            }
            // 失敗した場合
            Message({ message: 'お気に入りの登録に失敗しました', type: 'error' });
            this.property.data.favorites = false
        },
        removeFavorite() {
            if (this.apiStatus) {
                Message({ message: 'お気に入りから削除しました', type: 'success' });
                return
            }
            // 失敗した場合
            Message({ message: 'お気に入りの登録に失敗しました', type: 'error' });
            this.property.data.favorites = true
        },
        handleShare() {
            this.isShareModal = true
        },
        closeShareModal() {
            this.isShareModal = false
        },
        openLoginModal() {
            this.isLoginModal = true
        },
        closeLoginModal() {
            this.isLoginModal = false
        }
    }
}
</script>

<style lang="scss" scoped>
.detail {
    margin-top: 100px;
}
</style>