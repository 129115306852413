<template>
    <div>
        <input class="file" type="file" name="file-input" :accept="accept" id="file-input" @change="handleFileChange" />
        <label for="file-input">ファイルを選択</label>
    </div>
</template>

<script>
export default {
    name: 'BaseFileInput',

    props: {
        accept: {
            type: String,
            required: false
        }
    },

    methods: {
        handleFileChange(e) {
            this.$emit('change', e)
        }
    }
}
</script>

<style lang="scss" scoped>

input[type="file"] {
    display: none;
}

label {
    color: #000;
    border: 1px solid #ccc;
    font-size: 0.8rem;
    padding: 8px 10px;
    border-radius: 2px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 8px;

    &:hover {
        opacity: 0.7;
        transition: 0.3s ease-out;
    }

    &:after {
        content: "";
        color: #333;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        position: absolute;
        right: -140px;
        top: calc(50% - 10px);
    }

    &.changed:after {
        content: "";
    }
}
</style>