import Auth from '@/infrastructure/network/api/Auth'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import { OK, CREATED, UNAUTHENTICATED, UNPROCESSABLE_ENTITY } from '@/utils/const'

const state = {
    apiStatus: null,
    profile: null,
    errors: {},
    user: null,
    isSetUser: null,
    responseMessage: null,
    redirectUrl: null,
}

const getters = {
    isAdmin: state => {
        return state.user !== null && state.user.role === 'admin' && state.user.is_superuser === 1
    }
}

const mutations = {
    SET_API_STATUS(state, status) {
        state.apiStatus = status
    },
    SET_ERRORS(state, errors) {
        state.errors = errors
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_PROFILE(state, profile) {
        state.profile = profile
    },
    SET_USER_INFO(state, status) {
        state.isSetUser = status
    },
    SET_RESPONSE_MESSAGE(state, message) {
        state.responseMessage = message
    },
    SET_REDIRECT_URL(state, redirect_url) {
        state.redirectUrl = redirect_url
    }
}

const actions = {

    // Eメールログイン
    async loginWithEmail(context, profile) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        const response = await Auth.loginWithEmail(profile)

        await context.dispatch('loginResponseHandler', response)

        return response
    },

    // SNSログイン
    async loginWithProvider(context, { viewContext, provider }) {
        let profile = {
            access_token: '',
            provider: '',
            email: '',
            first_name: '',
            id: '',
            last_name: '',
            name: '',
            picture: ''
        }

        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        // ユーザー情報を取得するためのアクセス許可情報取得
        const access = await Auth.getAccessTokenFromProvider(viewContext, provider)

        // AccessTokenのセット
        profile.access_token = access.access_token
        profile.provider     = provider

        // プロバイダー経由でユーザー情報を取得
        const SNSProfile = await Auth.fetchUserProfileFromProvider(viewContext, provider)

        // ユーザー情報のセット
        profile.id         = SNSProfile.id
        profile.name       = SNSProfile.name
        profile.email      = SNSProfile.email
        profile.first_name = SNSProfile.first_name
        profile.last_name  = SNSProfile.last_name
        profile.picture    = SNSProfile.picture
        context.commit('SET_PROFILE', profile)

        // SNSログイン
        const response = await Auth.loginWithProvider(profile)

        // 正常
        await context.dispatch('loginResponseHandler', response)

        return response
    },

    async saveTemporaryUser(context, profile) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        const response = await Auth.saveTemporaryUser(profile)

        if (response.status === OK || response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        context.commit('SET_API_STATUS', false)
    },

    // レスポンスの処理
    async loginResponseHandler(context, response) {

        // 認証成功
        if (response.status === OK) {
            const accessToken = response.data.data.access_token
            AuthRepository.saveToken(accessToken)
            context.commit('SET_API_STATUS', true)
            return
        }

        // バリデーションエラー
        if (response.status === UNPROCESSABLE_ENTITY) {
            context.commit('SET_ERRORS', response.data.errors)
            context.commit('SET_API_STATUS', false)
            return
        }

        // メールが存在しない
        if (response.status === 403) {
            context.commit('SET_ERRORS', {'unauthorized': `${response.data.application}` })
            context.commit('SET_API_STATUS', false)
            return
        }

        // 認証失敗
        if (response.status === UNAUTHENTICATED) {
            context.commit('SET_ERRORS', {'unauthorized': 'メールアドレスおよびパスワードをご確認ください。' })
            context.commit('SET_API_STATUS', false)
            return
        }

        // システムエラー
        context.commit('SET_ERRORS', response.data.errors)
        context.commit('SET_API_STATUS', false)
    },

    // 新規会員登録
    async register(context, profile) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        // 登録処理
        const response = await Auth.register(profile)

        await context.dispatch('responseHandler', response)
    },

    // パスワード再設定リクエスト
    async requestResetPassword(context, profile) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        // パスワード再設定リクエスト
        const response = await Auth.requestResetPassword(profile)

        await context.dispatch('responseHandler', response)
    },

    // パスワード再設定
    async reRegisterPassword(context, profile) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        // パスワード再設定
        const response = await Auth.reRegisterPassword(profile)

        await context.dispatch('responseHandler', response)
    },

    // レスポンスの処理
    async responseHandler(context, response) {

        // 成功
        if (response.status === OK || response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        // バリデーションエラー
        if (response.status === UNPROCESSABLE_ENTITY) {
            context.commit('SET_ERRORS', response.data.errors)
            context.commit('SET_API_STATUS', false)
            return
        }

        // システムエラー
        context.commit('SET_ERRORS', {'message': 'システムエラーです' })
        context.commit('SET_API_STATUS', false)
    },

    // ログアウト
    async logout(context) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})

        // ログアウト
        const response = await Auth.logout();

        if (response.status === OK) {
            context.commit('SET_API_STATUS', true)
            AuthRepository.removeToken()
            return
        }

        context.commit('SET_ERRORS', response.data.errors)
        context.commit('SET_API_STATUS', false)
    },

    // 認証チェック
    async checkAuthed(context) {
        context.commit('SET_API_STATUS', null)
        context.commit('SET_ERRORS', {})
        context.commit('SET_USER_INFO', null)

        // ユーザー情報取得
        const response = await Auth.fetchUser()

        if (response.status === OK) {
            context.commit('SET_API_STATUS', true)
            context.commit('SET_USER', response.data)
            context.commit('SET_USER_INFO', true)
            return
        }

        context.commit('SET_API_STATUS', false)
        context.commit('SET_USER_INFO', false)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}