import Cookies from 'js-cookie'
import Jwt from '@/utils/jwt'

export default {

    // ログインの判定
    isLoggedIn() {
        if (this.checkValidExp() && !!this.getToken()) {
            return true
        }

        return false
    },

    // 有効期限の確認
    checkValidExp() {

        // Tokenがない場合
        if (!(!! this.getToken())) {
            return false
        }

        const jwt = Jwt.decode(this.getToken())

        if (jwt.exp < (new Date().getTime() + 1) / 1000) {
            return false
        }

        return true
    },

    // TokenをCookieに保持する
    saveToken(token) {
        Cookies.set('macro_sso_token', token, { expires: 1/6, domain: process.env.VUE_APP_DOMAIN })
    },

    // Tokenの取得
    getToken() {
        return Cookies.get('macro_sso_token')
    },

    // Tokenの削除
    removeToken() {
        Cookies.remove('macro_sso_token', { domain: process.env.VUE_APP_DOMAIN })
    },
}