import APIClient from '@/infrastructure/network/APIClient'

export default {

    // Eメールログイン
    async loginWithEmail(profile) {
        const payload = {
            email:    profile.email,
            password: profile.password,
            service: 'ENDREACH_PORTAL'
        }

        return await APIClient.post('/auth/login', payload)
            .catch(error => error.response || error)
    },

    // SNSログイン
    async loginWithProvider(profile) {
        const payload = {
            access_token: profile.access_token,
            provider:     profile.provider,
            email:        profile.email,
            first_name:   profile.first_name,
            id:           profile.id,
            last_name:    profile.last_name,
            name:         profile.name,
            picture:      profile.picture,
            service:      'ENDREACH_PORTAL'
        }

        return await APIClient.post('/auth/social/login', payload)
            .catch(error => error.response || error)
    },

    // プロバイダーからユーザー情報のアクセス権を取得
    async getAccessTokenFromProvider(context, provider) {
        const hello = context.hello

        return await hello(provider).login({ scope: 'email' }).then(() => {
            return hello(provider).getAuthResponse()
        })
    },

    // プロバイダー経由でユーザー情報を取得
    async fetchUserProfileFromProvider(context, provider) {
        const hello = context.hello

        return await hello(provider).api('me').then((response) => {
            return response
        })
    },

    // 新規会員登録
    async register(profile) {
        const payload = {
            profile: {
                username:              profile.username,
                email:                 profile.email,
                password:              profile.password,
                password_confirmation: profile.password_confirmation,
                last_name:             profile.last_name,
                first_name:            profile.first_name
            },
            type: 'ENDREACH_PORTAL'
        }

        return await APIClient.post('/auth/register', payload)
            .then(response => response)
            .catch(error => error.response)
    },

    // パスワード再設定リクエスト
    async requestResetPassword(profile) {
        const payload = {
            email: profile.email,
            type: 'ENDREACH_PORTAL'
        }

        return await APIClient.post('/auth/requestResetPassword', payload)
            .then(response => response)
            .catch(error => error.response)
    },

    // パスワード再設定
    async reRegisterPassword(profile) {
        const payload = {
            id: profile.id,
            password: profile.password,
            password_confirmation: profile.password_confirmation,
        }

        return await APIClient.post('/auth/updatePassword', payload)
            .then(response => response)
            .catch(error => error.response)
    },

    // ログアウト
    async logout() {
        return await APIClient.post('/auth/logout')
            .then(response => response)
            .catch(error => error.response)
    },

    // ユーザー情報取得
    async fetchUser() {
        return await APIClient.get('/auth/profile')
            .then(response => response)
            .catch(error => error.response)
    },

    async saveTemporaryUser(profile) {
        const payload = {
            access_token: profile.access_token,
            provider:     profile.provider,
            email:        profile.email,
            first_name:   profile.first_name,
            id:           profile.id,
            last_name:    profile.last_name,
            name:         profile.name,
            picture:      profile.picture,
            service:      'ENDREACH_PORTAL'
        }
        return await APIClient.post('/auth/saveTemporaryUser', payload)
            .then(response => response)
            .catch(error => error.response)
    }
}