import DocumentRequest from '@/infrastructure/network/api/DocumentRequest'
import { CREATED } from '@/utils/const'

const state = {
    apiStatus: null,
    errors: []
}

const mutations = {
    SET_API_STATUS(state, status) {
        state.apiStatus = status
    },

    SET_ERRORS(state, errors) {
        state.errors = errors
    }
}

const actions = {
    // 物件資料請求依頼
    async send(context, data) {
        context.commit('SET_API_STATUS', null)

        const response = await DocumentRequest.send(data)

        if (response.status === CREATED) {
            context.commit('SET_API_STATUS', true)
            return
        }

        context.commit('SET_API_STATUS', false)
        context.commit('SET_ERRORS', {
            message: 'システムエラーが発生しました'
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
