<template>
    <p class="base-tag" :class="[ type ? 'base-tag--' + type : '' ]"><slot /></p>
</template>

<script>
export default {
    name: 'BaseTag',
    props: {
        // 現状、normalしか存在しない
        type: {
            type: String,
            default: 'normal'
        }
    }
}
</script>

<style lang="scss" scoped>
.base-tag {
    display: inline-block;
    background: #f0f0f0;
    min-width: 75px;
    font-size: 14px;
    font-weight: 400;
    color: #112134;
    text-align: center;
    line-height: 1;
    white-space: nowrap;
    margin: 0 10px 0 0;
    padding: 6px 5px 5px 5px;

    max-width: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--normal {
        color: #3d4854;
    }
}
</style>