import APIClient from '@/infrastructure/network/APIClient'

export default {

    async send(params) {

        const payload = {
            property_id: params.property_id,
            name: params.name,
            email: params.email,
            property_url: params.property_url
        }

        return await APIClient.post('/endreachPortal/document-request/send', payload)
            .then(response => response)
            .catch(error => error)
    },
}