<template>
 <div class="propertyCard">

        <!-- header -->
        <div class="propertyCard__header">
            <base-tag>{{ property.data.buildingUse }}</base-tag>
        </div>

        <!-- body -->
        <div class="propertyCard__body">
            <div class="propertyCard__main">
                <div class="propertyCard__left">
                    <div class="propertyCard__image">
                        <el-image :src="property.data.imageUrl"></el-image>
                    </div>
                </div>
                <div class="propertyCard__right">
                    <div class="propertyCard__outline">
                        <div class="priceBox"><p>{{ changeJpPrice(property.data.price) }}円</p></div>
                        <div class="grossBox" v-if="property.data.gross"><p>利回り：<span class="grossBox--value">{{ property.data.gross }}%</span></p></div>
                    </div>

                    <div class="propertyCard__detail" v-if="property.data.buildingUse.includes('区分')">
                        <dl class="propertyCard__dl" style="width: 100%;">
                            <div>
                                <dt class="propertyCard__dt">住所：</dt>
                                <dd class="propertyCard__dd">{{ property.data.address }}</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">最寄り駅：</dt>
                                <dd class="propertyCard__dd">{{ property.data.station.name }}</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">駅距離：</dt>
                                <dd class="propertyCard__dd">{{ property.data.station.distance }}m（徒歩{{ property.data.station.walk_minutes }}分）</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">専有面積：</dt>
                                <dd class="propertyCard__dd">{{ property.data.occupiedArea }}㎡（{{ property.data.occupiedAreaTsubo }}坪）</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">専有坪単価：</dt>
                                <dd class="propertyCard__dd">{{ changeJpPrice(property.data.occupiedAreaPerUnit) }}円/坪</dd>
                            </div>
                        </dl>
                        <dl class="propertyCard__dl" style="width: 100%;">
                            <div>
                                <dt class="propertyCard__dt">建物構造：</dt>
                                <dd class="propertyCard__dd">{{ property.data.buildingStructure }} {{ property.data.buildingFloors }}</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">築年数：</dt>
                                <dd class="propertyCard__dd">{{ property.data.buildingOld }}年</dd>
                            </div>
                            <div v-if="property.data.remaining_useful_life">
                                <dt class="propertyCard__dt">残存耐用年数：</dt>
                                <dd class="propertyCard__dd">{{ property.remainingUsefulLife() }}</dd>
                            </div>
                            <div v-if="property.rentPrice()">
                                <dt class="propertyCard__dt">月額賃料：</dt>
                                <dd class="propertyCard__dd">{{ changeJpPrice(property.rentPrice()) }}円</dd>
                            </div>
                            <div v-if="property.data.rentMonthlyTsuboPrice">
                                <dt class="propertyCard__dt">月額賃料坪単価：</dt>
                                <dd class="propertyCard__dd">{{ addConma(property.data.rentMonthlyTsuboPrice) }}円</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">取得日時：</dt>
                                <dd class="propertyCard__dd">{{ dateFormat(property.data.acquisitionDate) }}</dd>
                            </div>
                        </dl>
                    </div>
                    <div class="propertyCard__detail" v-else>
                        <dl class="propertyCard__dl" style="width: 100%;">
                            <div>
                                <dt class="propertyCard__dt">住所：</dt>
                                <dd class="propertyCard__dd">{{ property.data.address }}</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">最寄り駅：</dt>
                                <dd class="propertyCard__dd">{{ property.data.station.name }}</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">駅距離：</dt>
                                <dd class="propertyCard__dd">{{ property.data.station.distance }}m（徒歩{{ property.data.station.walk_minutes }}分）</dd>
                            </div>
                            <div v-if="property.data.landArea">
                                <dt class="propertyCard__dt">土地面積：</dt>
                                <dd class="propertyCard__dd">{{ property.data.landArea }}㎡（{{ property.data.landAreaTsubo }}坪）</dd>
                            </div>
                            <div v-if="property.data.landAreaPerUnit">
                                <dt class="propertyCard__dt">土地坪単価：</dt>
                                <dd class="propertyCard__dd">{{ changeJpPrice(property.data.landAreaPerUnit) }}円/坪</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">建物構造：</dt>
                                <dd class="propertyCard__dd">{{ property.data.buildingStructure }} {{ property.data.buildingFloors }}</dd>
                            </div>
                        </dl>
                        <dl class="propertyCard__dl" style="width: 100%;">
                            <div>
                                <dt class="propertyCard__dt">延床面積：</dt>
                                <dd class="propertyCard__dd">{{ property.data.totalFloorArea }}㎡（{{ property.data.totalFloorAreaTsubo }}坪）</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">延床坪単価：</dt>
                                <dd class="propertyCard__dd">{{ changeJpPrice(property.data.totalFloorAreaPerUnit) }}円/坪</dd>
                            </div>
                            <div v-if="property.data.totalUnits && property.data.buildingUse.includes('一棟')">
                                <dt class="propertyCard__dt">総賃貸戸数：</dt>
                                <dd class="propertyCard__dd">{{ property.data.totalUnits }}戸</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">築年数：</dt>
                                <dd class="propertyCard__dd">{{ property.data.buildingOld }}年</dd>
                            </div>
                            <div v-if="property.data.remaining_useful_life != null">
                                <dt class="propertyCard__dt">残存耐用年数：</dt>
                                <dd class="propertyCard__dd">{{ property.remainingUsefulLife() }}</dd>
                            </div>
                            <div v-if="property.rentPrice()">
                                <dt class="propertyCard__dt">月額賃料：</dt>
                                <dd class="propertyCard__dd">{{ changeJpPrice(property.rentPrice()) }}円</dd>
                            </div>
                            <div v-if="property.data.rentMonthlyTsuboPrice">
                                <dt class="propertyCard__dt">月額賃料坪単価：</dt>
                                <dd class="propertyCard__dd">{{ addConma(property.data.rentMonthlyTsuboPrice) }}円</dd>
                            </div>
                            <div>
                                <dt class="propertyCard__dt">取得日時：</dt>
                                <dd class="propertyCard__dd">{{ dateFormat(property.data.acquisitionDate) }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <!-- footer -->
        <template v-if="isLink">
            <div class="propertyCard__footer">
                <div class="portalBtn-group">
                    <favorite-button @click="actionFavorite" :isFavorite="property.data.favorites" />
                    <portal-base-button type="share" @click="actionShare">シェアする</portal-base-button>
                </div>
            </div>

            <a v-if="isLoggedIn" @click="view" target="_blank" class="propertyCard__link"></a>
            <a v-else @click="login" class="propertyCard__link"></a>
        </template>
    </div>
</template>

<script>
import BaseTag from '@/components/atoms/tags/BaseTag'
import PortalBaseButton from '@/components/molecules/buttons/PortalBaseButton'
import FavoriteButton from '@/components/molecules/buttons/FavoriteButton'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import { changeJpPrice, addConma } from '@/utils/price'
import { format } from '@/utils/date'

export default {
    name: 'PropertyCard',

    components: {
        BaseTag,
        PortalBaseButton,
        FavoriteButton
    },

    props: {
        property: {
            type: Object,
            required: false
        },
        isLink: {
            type: Boolean,
            required: false,
            'default': true
        }
    },

    computed: {
        isLoggedIn() {
            return AuthRepository.isLoggedIn()
        },
        favoriteTitle() {
            return this.property.data.favorites ? '登録済み' : 'お気に入りに登録する'
        },
    },

    methods: {
        // お気に入り登録をする
        actionFavorite() {
            this.$emit('favorite', this.property)
        },
        // シェアをする
        actionShare() {
            this.$emit('share', this.property)
        },
        // ログインモーダルを出す
        login() {
            this.$emit('login')
        },
        changeJpPrice(price) {
            return changeJpPrice(price)
        },
        addConma(price) {
            return addConma(price)
        },
        dateFormat(date) {
            return format(date)
        },
        view() {
            // ロギング処理
            this.$store.dispatch('property/logging', this.property.data.id)

            // 登録フォームに遷移
            this.$router.push({ name: 'documentRequestForm', params: { propertyId: this.property.data.id } })
        }
    },
}
</script>

<style lang="scss" scoped>

// ボタン
.portalBtn-group {
    display: flex;
    justify-content: flex-end;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 1px;
    z-index: 10;

    @media screen and (max-width: 414px) {
        justify-content: space-around;
        width: 100%;
    }

    & > div {
        margin-right: 20px;

        @media screen and (max-width: 414px) {
           margin: 0 10px;
        }
    }
}
</style>