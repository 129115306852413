<template>
    <div>
        <div class="login">

            <div class="signin signin--reRegister">
                <validation-observer tag='div' ref="login" v-slot="{ invalid }">
                    <h4 class="signin__title">新しいパスワードを入力</h4>

                    <p v-if="errors.message" class="errors">{{ errors.message }}</p>

                    <base-input-field name="password" type="password" label="パスワード" rules="required|max:20" :apiErrors="errors" @update:field="profile.password = $event" required />
                    <base-input-field name="password_confirmation" type="password" label="パスワード(確認用)" rules="required" :apiErrors="errors" @update:field="profile.password_confirmation = $event" required />

                    <div>
                        <base-button @click="reRegisterPassword" class="signin__login" :disabled="invalid">パスワードを更新する</base-button>
                    </div>
                </validation-observer>

            </div>
        </div>
    </div>
</template>

<script>
import BaseInputField from '@/components/atoms/inputs/BaseInputField'
import BaseButton from '@/components/atoms/buttons/BaseButton'
import { ValidationObserver } from "vee-validate";
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';

export default {
    name: 'ReRegisterPassword',

    components: {
        BaseInputField,
        BaseButton,
        ValidationObserver
    },

    computed: {
        ...mapState({
            apiStatus: state => state.auth.apiStatus,
            errors: state => state.auth.errors
        })
    },

    data() {
        return {
            profile: {
                id: this.$route.query.id,
                password: '',
                password_confirmation: ''
            }
        }
    },

    methods: {
        // パスワード再設定
        async reRegisterPassword() {
            let loadingInstance = Loading.service();
            await this.$store.dispatch('auth/reRegisterPassword', this.profile)
            this.$nextTick(() => {
                loadingInstance.close();
            });

            if (this.apiStatus) {
                Message({
                    message: 'パスワードを更新しました',
                    type: 'success',
                    duration: 0,
                    showClose: true
                });

                setTimeout(() => {
                    this.$router.push({ name: 'login' })
                }, 3000);
            }
        }
    }
}
</script>