import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VeeValidate from './plugins/vee-validate'

Vue.config.productionTip = false

/*---------------Element UI------------------*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ja'
Vue.use(ElementUI, { locale })

/*---------------OAuth------------------*/
const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');
HelloJs.init({
  google: process.env.VUE_APP_GOOGLE_APP_CLIENT_ID,
  facebook: process.env.VUE_APP_FACEBOOK_APP_CLIENT_ID
}, {
  redirect_uri: '/authcallback'
});
Vue.use(VueHello, HelloJs);

require('@/assets/scss/main.scss');

/*---------------POPPER------------------*/
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');

/*---------------Bootstrap------------------*/
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  VeeValidate,
  render: h => h(App),
}).$mount('#app')
