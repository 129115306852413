export const LoanList1 = [
    {
        name: '27,083円',
        value: 27083
    },
    {
        name: '40,625円',
        value: 40625
    },
    {
        name: '54,166円',
        value: 54166
    },
    {
        name: '67,708円',
        value: 67708
    },
    {
        name: '81,250円',
        value: 81250
    },
    {
        name: '94,791円',
        value: 94791
    },
    {
        name: '108,333円',
        value: 108333
    },
    {
        name: '121,875円',
        value: 121875
    },
    {
        name: '135,416円',
        value: 135416
    },
    {
        name: '148,958円',
        value: 148958
    },
    {
        name: '162,500円',
        value: 162500
    },
    {
        name: '176,041円',
        value: 176041
    },
    {
        name: '189,583円',
        value: 189583
    },
    {
        name: '203,125円',
        value: 203125
    },
    {
        name: '216,666円',
        value: 216666
    },
    {
        name: '230,208円',
        value: 230208
    },
    {
        name: '243,750円',
        value: 243750
    },
    {
        name: '257,291円',
        value: 257291
    },
    {
        name: '270,833円',
        value: 270833
    }
]