import Vue from 'vue'
import { extend, localize } from 'vee-validate';
import { required, email, between, numeric, min, max, alpha_num, alpha_spaces } from 'vee-validate/dist/rules';
import ja from 'vee-validate/dist/locale/ja.json';
import en from 'vee-validate/dist/locale/en.json';

export class VueVeeValidate {

    constructor() {

        extend('required', required);
        extend('email', email);
        extend('between', between);
        extend('numeric', numeric);
        extend('min', min);
        extend('max', max);
        extend('alpha_num', alpha_num);
        extend('alpha_spaces', alpha_spaces);

        extend('formatUrl', {
            message: {
                ja: ja.messages.formatUrl = (value) => `${value}は正しいURLを設定してください。`
            },
            validate: (value) => {
                let regex = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');
                return regex.test(value) ? true : false
            },
        });

        extend('lengthBetween', {
            computesRequired: true,
            message: {
                ja: ja.messages.lengthBetween = (value, { min, max }) => `${value}は${min}〜${max}文字以内で入力してください。`
            },
            params: ['min', 'max'],
            validate: (value, { min, max }) => {
                const length = value && value.length;
                return length >= min && length <= max;
            },
        });

        extend('confirmPassword', {
            message: {
                ja: ja.messages.confirmPassword = () => `パスワードが一致しません。`
            },
            params: [{ name: 'other', isTarget: true }],
            validate: (value, { other }) => value === other
        });

        localize({
            ja, en
        });

        // A simple get/set interface to manage our locale in components.
        // This is not reactive, so don't create any computed properties/watchers off it.
        Object.defineProperty(Vue.prototype, 'locale', {
            get() {
                return LOCALE;
            },
            set(val) {
                LOCALE = val;
                localize(val);
            },
        });

        // ブラウザ言語判定
        let LOCALE = '';
        if (navigator.browserLanguage != null) {
            // Internet Explorer, Opera, 他
            LOCALE = navigator.browserLanguage.substr(0, 2);
        } else if (navigator.userLanguage != null) {
            // Internet Explorerの場合
            LOCALE = navigator.userLanguage.substr(0, 2);
        } else if (navigator.language != null) {
            // Chrome, Firefox, Opera, 他
            LOCALE = navigator.language.substr(0, 2);
        } else {
            // その他
            LOCALE = 'ja';
        }

        localize(LOCALE);
    }
}

const VeeValidate = new VueVeeValidate()

export default VeeValidate