export const AreaList = [
    {
        value: '千代田区',
        id: 1
    },
    {
        value: '中央区',
        id: 2
    },
    {
        value: '港区',
        id: 3
    },
    {
        value: '新宿区',
        id: 4
    },
    {
        value: '文京区',
        id: 5
    },
    {
        value: '台東区',
        id: 6
    },
    {
        value: '墨田区',
        id: 7
    },
    {
        value: '江東区',
        id: 8
    },
    {
        value: '品川区',
        id: 9
    },
    {
        value: '目黒区',
        id: 10
    },
    {
        value: '大田区',
        id: 11
    },
    {
        value: '世田谷区',
        id: 12
    },
    {
        value: '渋谷区',
        id: 13
    },
    {
        value: '中野区',
        id: 14
    },
    {
        value: '杉並区',
        id: 15
    },
    {
        value: '豊島区',
        id: 16
    },
    {
        value: '北区',
        id: 17
    },
    {
        value: '荒川区',
        id: 18
    },
    {
        value: '板橋区',
        id: 19
    },
    {
        value: '練馬区',
        id: 20
    },
    {
        value: '足立区',
        id: 21
    },
    {
        value: '葛飾区',
        id: 22
    },
    {
        value: '江戸川区',
        id: 23
    },
]