<template>
    <div class="link-btn">
        <a
            class="link-btn__anchor"
            :href="href"
            :target="blank ? '_blank' : ''"
            :rel="rel"
        >
            <i :class="icon"></i>
            <span class="link-btn__text"><slot /></span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'LinkButton',

    props: {
        href: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: ''
        },
        rel: String,
        blank: Boolean,
    }
}
</script>

<style lang="scss" scoped>
.link-btn {

    width: 150px;
    padding: 8px 10px;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    margin: 3px;

    @media screen and (max-width: 490px) {
        width: -webkit-fill-available;
    }

    &__anchor {
        text-decoration: none;
        color: white;
        display: block;

        &:hover {
            text-decoration: none;
            color: white;
        }
    }

    &__text {
        margin-left: 8px;
    }

}
</style>