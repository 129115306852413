export const RemainUsefulLife = [
    {
        name: '1年',
        value: 1
    },
    {
        name: '2年',
        value: 2
    },
    {
        name: '3年',
        value: 3
    },
    {
        name: '4年',
        value: 4
    },
    {
        name: '5年',
        value: 5
    },
    {
        name: '6年',
        value: 6
    },
    {
        name: '7年',
        value: 7
    },
    {
        name: '8年',
        value: 8
    },
    {
        name: '9年',
        value: 9
    },
    {
        name: '10年',
        value: 10
    },
    {
        name: '11年',
        value: 11
    },
    {
        name: '12年',
        value: 12
    },
    {
        name: '13年',
        value: 13
    },
    {
        name: '14年',
        value: 14
    },
    {
        name: '15年',
        value: 15
    },
    {
        name: '16年',
        value: 16
    },
    {
        name: '17年',
        value: 17
    },
    {
        name: '18年',
        value: 18
    },
    {
        name: '19年',
        value: 19
    },
    {
        name: '20年',
        value: 20
    },
    {
        name: '25年',
        value: 25
    },
    {
        name: '30年',
        value: 30
    },
    {
        name: '35年',
        value: 35
    },
    {
        name: '40年',
        value: 40
    },
    {
        name: '45年',
        value: 45
    },
]