<template>
    <link-button
        :class="[ type ? 'link-btn--' + type : '' ]"
        :href="href"
        :icon="icon"
        rel="nofollow noopener noreferrer"
        blank
    ><slot /></link-button>
</template>

<script>
import LinkButton from '@/components/atoms/buttons/LinkButton'

export default {
    name: 'ShareButton',

    components: {
        LinkButton
    },

    props: {
        type: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
// SHARE_BUTTON
.link-btn {

    ///////////////////////////////
    // LINE
    &--line {
        background-color: #00b900;
        &:hover {
            background-color: #019e01;
        }
    }
    //////////////////////////////
    // FACEBOOK
    &--facebook {
        background-color: #3b5998;
        &:hover {
            background-color: #274992;
        }
    }
    //////////////////////////////
    // TWITTER
    &--twitter {
        background-color: #55acee;
        &:hover {
            background-color: #339ae9;
        }
    }
    //////////////////////////////
    // EMAIL
    &--email {
        background-color: grey;
        &:hover {
            background-color: rgb(104, 103, 103);
        }
    }
}
</style>