<template>
    <portal-base-button type="favorite" @click="action" :class="{ 'is-favorite': isFavorite }">{{ favoriteTitle }}</portal-base-button>
</template>

<script>
import PortalBaseButton from '@/components/molecules/buttons/PortalBaseButton'

export default {
    name: 'FavoriteButton',

    components: {
        PortalBaseButton
    },

    props: {
        isFavorite: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        favoriteTitle() {
            return this.isFavorite ? '登録済み' : 'お気に入り登録'
        }
    },

    methods: {
        action() {
            return this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.portalBtn {

    &--favorite {
        border: 1px solid #F77C00;
        color: #0c0600;
        transition: all .2s;
        font-weight: 500;

        &:hover {
            font-weight: 800;
            background-color: #F77C00;
            color: #fff;
        }
    }
}
.is-favorite {
    font-weight: 800;
    background-color: #F77C00;
    color: #fff;
}
</style>