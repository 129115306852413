<template>
    <div v-loading="loading" class="form">
        <h3>資料請求</h3>

        <div v-if="!isFinished">
            <property-card
                :property="property"
                :is-link="false"
            />

            <el-form ref="form" :model="form">
                <el-form-item
                    label="氏名"
                    prop="name"
                    :rules="[
                        { required: true, message: '氏名を入力してください', trigger: 'blur' },
                    ]">
                    <el-input placeholder="田中 太郎" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item
                    label="メールアドレス"
                    prop="email"
                    :rules="[
                        { required: true, message: 'メールアドレスを入力してください', trigger: 'blur' },
                        { type: 'email', message: 'メールアドレスの形式が違います', trigger: 'blur' }
                    ]">
                    <el-input placeholder="sample@mandeve.site" v-model="form.email"></el-input>
                </el-form-item>

                <div class="request-btn">
                    <el-button type="success" @click="submitForm('form')">請求する</el-button>
                </div>
            </el-form>
        </div>
        <div v-else>
            資料請求が完了しました。<br>
            ご連絡をお待ちください。
        </div>
    </div>
</template>

<script>
import PropertyCard from '@/components/organisms/cards/PropertyCard'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
export default {
    name: 'Form',

    components: {
        PropertyCard,
    },

    data() {
        const url = this.$router.resolve({
            name: 'detail',
            params: {
                id: this.$route.params.propertyId
            }
        }).href

        return {
            id: this.$route.params.propertyId,
            loading: true,
            form: {
                property_id: this.$route.params.propertyId,
                name: '',
                email: '',
                property_url: location.origin + url,
            },
            isFinished: false,
        }
    },
    async mounted() {
        await this.fetchProperty()
        if (!this.property) {
            Message({ message: 'システムエラーが発生しました', type: 'error' });
            setTimeout(() => {
                this.$router.push({ name: 'portalIndex'})
            }, 3000);
            return
        }
        // ユーザー情報取得まで待機
        do
            setTimeout(() => {
                console.log('wait user info')
            }, 1000)
        while(this.isSetUser == null)

        if (this.user) {
            this.form.name = `${this.user.last_name} ${this.user.first_name}`
            this.form.email = this.user.email
        }
        this.loading = false
    },

    computed: {
        ...mapState({
            apiStatus: state => state.documentRequest.apiStatus,
            property: state => state.property.property,
            user: state => state.auth.user,
            isSetUser: state => state.auth.isSetUser,
        }),
    },

    methods: {
        async fetchProperty() {
            await this.$store.dispatch('property/fetchProperty', this.id)
        },
        async send() {
            this.loading = true
            await this.$store.dispatch('documentRequest/send', this.form)
            if (!this.apiStatus) {
                Message({ message: 'システムエラーが発生しました', type: 'error' });
            }

            this.loading = false
            this.isFinished = true
        },
        async submitForm(formName) {
            await this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.send()
                } else {
                    console.log('validation error occured.')
                    return false
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 40px auto;
    width: 80%;

    &::v-deep {
        .el-form-item {
            display: block;

            @media screen and (min-width: 414px) {
                display: flex;
            }
        }
        .el-form-item__label {
            width: 100%;
            text-align: left;

            @media screen and (min-width: 414px) {
                width: 120px;
            }
        }
        .el-form-item__content {
            @media screen and (min-width: 414px) {
                width: calc(100% - 120px);
            }
        }
    }
    .request-btn {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
