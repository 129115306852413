<template>
    <header id="user-header">
        <div class="logo">
            <router-link class="logo__link" :to="{ name: 'portalIndex' }">ENDREACH</router-link>
        </div>
        <div class="header__menu">
            <div v-if="!isLoggedIn" class="header__item">
                <a class="header__link" :href="accountUrl" target="_blank">
                    <img src="../../../assets/images/header/register.png" class="icon">
                    <p>新規登録</p>
                </a>
            </div>

            <div v-if="!isLoggedIn" class="header__item">
                <router-link class="header__link" :to="{ name: 'login' }">
                    <img src="../../../assets/images/header/user.png" class="icon">
                    <p>ログイン</p>
                </router-link>
            </div>

            <div v-if="isLoggedIn" class="header__item">
                <router-link class="header__link" :to="{ name: 'favorite' }">
                    <img src="../../../assets/images/header/hart.png" class="icon">
                    <p>お気に入り</p>
                </router-link>
            </div>

            <div v-if="isLoggedIn && isAdmin" class="header__item">
                <router-link class="header__link" :to="{ name: 'csvUpload' }">
                    <img src="../../../assets/images/header/upload.png" class="icon">
                    <p>アップロード</p>
                </router-link>
            </div>
            <div v-if="isLoggedIn && isAdmin" class="header__item">
                <router-link class="header__link" :to="{ name: 'adminProperty' }">
                    <img src="../../../assets/images/header/settings.png" class="icon">
                    <p>公開設定</p>
                </router-link>
            </div>
            <div v-if="isLoggedIn" class="header__item">
                <router-link class="header__link" :to="{ name: 'portalIndex' }" @click.native="logout">
                    <img src="../../../assets/images/header/user.png" class="icon">
                    <p>ログアウト</p>
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
    name: "UserHeader",

    computed: {
        ...mapState({
            apiStatus: state => state.auth.apiStatus
        }),
        isAdmin() {
            return this.$store.getters['auth/isAdmin']
        },
        isLoggedIn() {
            return AuthRepository.isLoggedIn()
        },
        accountUrl() {
            return process.env.VUE_APP_ACCOUNT_URL + '?via=endreach-portal'
        }
    },

    async mounted() {
        await this.$store.dispatch('auth/checkAuthed')
    },

    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout')

            if (this.apiStatus) {

                Message({
                    message: 'ログアウトしました',
                    type: 'success',
                    duration: 0,
                    showClose: true
                });

                setTimeout(() => {
                    this.$router.push({ name: 'login' })
                }, 3000);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#user-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    @media screen and (min-width: 420px) {
        height: 100px;
    }
}

.header {
    &__menu {
        display: flex;
        margin-right: 50px;

        @media screen and (max-width: 420px) {
            margin-right: 10px;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: 20px;
        }

        &:hover {
            opacity: .8;
        }
    }

    &__link {
        text-decoration: none;
        color: #000;
        font-size: 12px;

        &:not(:last-child) {
            margin-right: 20px;
        }

        & > p {
            margin: 0;

            @media screen and (max-width: 420px) {
                display: none;
            }
        }
    }
}

.icon {
    width: 23px;
    height: 25x;
    display: block;
    margin: 0 auto 6px;
}
</style>