export const LoanList2 = [
    {
        name: '28,250円',
        value: 28250
    },
    {
        name: '42,375円',
        value: 42375
    },
    {
        name: '56,500円',
        value: 56500
    },
    {
        name: '70,625円',
        value: 70625
    },
    {
        name: '84,750円',
        value: 84750
    },
    {
        name: '98,875円',
        value: 98875
    },
    {
        name: '113,000円',
        value: 113000
    },
    {
        name: '127,125円',
        value: 127125
    },
    {
        name: '141,250円',
        value: 141250
    },
    {
        name: '155,275円',
        value: 155275
    },
    {
        name: '169,500円',
        value: 169500
    },
    {
        name: '183,625円',
        value: 183625
    },
    {
        name: '197,750円',
        value: 197750
    },
    {
        name: '211,875円',
        value: 211875
    },
    {
        name: '226,000円',
        value: 226000
    },
    {
        name: '240,125円',
        value: 240125
    },
    {
        name: '254,250円',
        value: 254250
    },
    {
        name: '268,375円',
        value: 268375
    },
    {
        name: '282,500円',
        value: 282500
    }
]