<template>
    <validation-provider mode="lazy" class="input-field" tag="div" v-slot="{ errors }" :rules="rules" :name="label">
        <label :for="name" class="input-field__label" :class="{ required: required }">{{ label }}</label>
        <input :id="name" :type="type" class="input-field__input" :class="errorClassObject()" :placeholder="placeholder" v-model="value" @input="updateField()">

        <p class="input-field--errors">{{ errors[0] || errorMessage() }}</p>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
    name: 'BaseInputFiled',

    components: {
        ValidationProvider
    },

    props: {
        name: {
            type: String
        },
        label: {
            type: String
        },
        placeholder: {
            type: String
        },
        apiErrors: {
            type: Object
        },
        data: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        rules: {
            type: String
        },
        required: Boolean
    },

    data() {
        return {
            value: ''
        }
    },

    computed: {
        hasError() {
            return this.apiErrors && this.apiErrors[this.name] && this.apiErrors[this.name].length > 0;
        }
    },

    methods: {
        updateField() {
            this.clearErrors(this.name);

            this.$emit('update:field', this.value)
        },

        errorMessage() {
            if (this.hasError) {
                return this.apiErrors[this.name][0];
            }
        },

        clearErrors() {
            if (this.hasError) {
                this.apiErrors[this.name] = null;
            }
        },

        errorClassObject() {
            return {
                'error-field': this.hasError
            }
        }
    },

    watch: {
        data(val) {
            this.value = val;
        }
    }
}
</script>

<style lang="scss" scoped>
.input-field {
    font-size: 13px;
    width: 100%;
    position: relative;

    &__label {
        color: #222;
    }

    &__input {
        width: 100%;
        height: 36px;
        padding: 0 12px;
        outline: 0;
        border: 1px solid #ccc;
        margin-top: 5px;

        &:hover {
            cursor: pointer;
            border: 1px solid #0081cc;
            transition: all .4s;
        }

        &:focus {
            border: 1px solid #0081cc;
        }
    }

    &--errors {
        color: #d53333;
    }
}

.required {
    &::before {
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
    }
}

.error-field {
    border: 1px solid #d53333;
}
</style>