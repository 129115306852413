<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',

  async created() {
    await this.$store.dispatch('auth/checkAuthed')
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
