<template>
    <div>
        <Header />
        <div class="firstview">
            <div class="firstview__image">
                <div class="firstview__text">
                    <span>AI物件自動更新＆配信検索ポータル</span><br>
                    <span>END REACH</span>
                </div>
            </div>
        </div>
        <div id="layout-portal">
            <router-view class="base-main"></router-view>
        </div>
    </div>
</template>

<script>
import Header from '@/components/organisms/headers/UserHeader'

export default {
  name: 'LayoutTop',

  components: {
      Header
  }
}
</script>

<style lang="scss">
.firstview {
    margin-bottom: 20px;

    &__image {
        width: 100%;
        height: 57vw;
        max-height: 470px;
        background-image: url('../assets/images/top/firstview.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 93%;
        padding-right: 10vw;
    }

    &__text {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
        position: relative;
        top: 24%;
        left: 8vw;
        width: 92vw;

        @media screen and (min-width: 685px) {
            width: 80%;
            font-size: 30px;
            left: 15vw;
        }
    }
}

#layout-portal {
    width: 986px;
    margin: 0 auto;

    @media screen and (max-width: 1035px) {
        width: 100%;
    }
}
</style>