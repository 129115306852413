<template>
    <div class="frame">
        <div class="frame__container">
            <h2 class="frame__title">メール認証完了</h2>
            <h4 class="frame__message">メール認証が完了しました。ログインし直してください</h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Activate',

    created() {
        setTimeout(() => {
            this.$router.push({ name: 'login' })
        }, 3000);
    }
}
</script>

<style lang="scss" scoped>
.frame {
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 420px) {
        &__title {
            font-size: 18px;
        }

        &__message {
            font-size: 14px;
        }
    }

    &__container {
        margin-top: 200px;
    }
}
</style>