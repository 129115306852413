<template>
    <div id="normal">
        <router-view class="base-main"></router-view>
    </div>
</template>

<script>
export default {
    name: 'LayoutNormal'
}
</script>