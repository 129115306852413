<template>
    <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    @close="close">
        <span>{{ text }}</span>
        <span slot="footer" class="dialog-footer">
            <el-button label-width="200" @click="close">キャンセル</el-button>
            <el-button label-width="200" type="primary" @click="login">ログインする</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: "LoginModal",

    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            title: 'ログイン',
            text: '詳細を閲覧する場合はログインしてください'
        }
    },

    methods: {
        close() {
            return this.$emit('close')
        },
        login() {
            return this.$emit('login')
        }
    }
}
</script>

<style lang="scss">
.el-dialog {
    width: 80% !important;
    max-width: 400px !important;
}
.el-button--primary {
    background-color: #F77C00 !important;
    border-color: #F77C00 !important;

    &:hover {
        background-color: #F77C00 !important;
        border-color: #F77C00 !important;
        opacity: 0.8 !important;
    }
}
</style>