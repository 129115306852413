import axios from 'axios'
import AuthRepository from '@/infrastructure/repositories/AuthRepository'
import router from '@/router/index.js'
import { UNAUTHENTICATED } from '@/utils/const'
import { Message } from 'element-ui'

const APIClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    }
});

// リクエスト前共通処理
APIClient.interceptors.request.use(config => {

    // 認証Tokenをヘッダーにいれる
    config.headers['Authorization'] = `Bearer ${(AuthRepository.getToken())}`

    return config
})

// エラー共通処理
APIClient.interceptors.response.use(null, error => {

    switch (error.response.status) {
        case UNAUTHENTICATED:
            // ログインしてる場合のみメッセージを表示する
            if (AuthRepository.isLoggedIn()) {
                Message({
                    message: 'ログアウトされました。3秒後にログイン画面に遷移します',
                    type: 'error'
                })

                setTimeout(() => {
                    router.push({ name: 'login' })
                }, 3000);
            }

            // トークンを削除する
            AuthRepository.removeToken()
            break

        default:
            break;
    }

    return Promise.reject(error);
})

export default APIClient;