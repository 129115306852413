<template>
    <header id="login-header">
        <div class="logo">
            <router-link class="logo__link" :to="{ name: 'portalIndex' }">ENDREACH</router-link>
        </div>
    </header>
</template>

<script>
export default {
    name: "LoginHeader"
}
</script>

<style lang="scss" scoped>
#login-header {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    @media screen and (min-width: 420px) {
        height: 100px;
    }
}
</style>