<template>
    <button
        @click="handleClick"
        class="base-btn"
        type="button"
        :disabled="disabled"
        :class="[
            size ? 'base-btn--' + size : '',
            type ? 'base-btn--' + type : '',
            {
                'is-disabled': disabled,
                'is-round': round,
                'is-circle': circle
            }
        ]"
    >
        <i :class="icon"></i>
        <span><slot /></span>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',

    props: {
        type: {
            type: String,
            default: 'default'
        },
        icon: {
            type: String,
            default: ''
        },
        // [small, medium, large]
        size: {
            type: String,
            default: 'medium'
        },
        disabled: Boolean,
        round: Boolean,
        circle: Boolean
    },

    methods: {
        handleClick() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.base-btn {

    display: inline-block;
    cursor: pointer;
    background: white;
    color: #000;
    border: 1px solid #ccc;
    margin: 0;
    outline: none;
    text-align: center;
    transition: .1s;
    border-radius: 2px;
    width: 100%;

    transition: all .2s linear;

    & > span {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: whitesmoke;
        color: #2e5a7f;
    }

    &:focus {
        outline: 0;
    }

    /////////////////////////////////
    // SIZE

    &--small {
        padding: 6px 10px;
        font-size: 10px;
    }

    &--medium {
        padding: 8px 12px;
        font-size: 12px;
    }

    &--large {
        padding: 10px 26px;
        font-size: 14px;
    }

}

.is-disabled {
    cursor: not-allowed;
    background-color: rgb(247, 245, 245);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(180, 177, 177);

    &:hover {
        background-color: rgb(247, 245, 245);
    }
}

.is-round {
    border-radius: 20px;
}

.is-circle {
    border-radius: 50%;
}
</style>