var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"base-btn",class:[
        _vm.size ? 'base-btn--' + _vm.size : '',
        _vm.type ? 'base-btn--' + _vm.type : '',
        {
            'is-disabled': _vm.disabled,
            'is-round': _vm.round,
            'is-circle': _vm.circle
        }
    ],attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_c('i',{class:_vm.icon}),_c('span',[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }