export const Land = [
    {
        id: 25,
        value: '25㎡'
    },
    {
        id: 50,
        value: '50㎡'
    },
    {
        id: 75,
        value: '75㎡'
    },
    {
        id: 100,
        value: '100㎡'
    },
    {
        id: 125,
        value: '125㎡'
    },
    {
        id: 150,
        value: '150㎡'
    },
    {
        id: 175,
        value: '175㎡'
    },
    {
        id: 200,
        value: '200㎡'
    },
    {
        id: 225,
        value: '225㎡'
    },
    {
        id: 250,
        value: '250㎡'
    },
    {
        id: 275,
        value: '275㎡'
    },
    {
        id: 300,
        value: '300㎡'
    }
]